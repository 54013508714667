import React, { useEffect, useState } from "react";
import "../../../style.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SingleJob from "components/SingleJob/SingleJob";
import Footer from "components/Footers/Footer";
import Header from "components/Footers/Header";

const Jobs = ({
  data,
  selectJobHandler,
  languageToggle,
  setLanguageToggle,
  setGeneralJobSelected,
  setdisplayViewJobDescriptonPanel,
}) => {
  const [search, setSearch] = useState("");
  const [location, setLocation] = useState("");
  const [department, setDepartment] = useState("");
  const [workType, setWorkType] = useState("");
  let [allData, setAllData] = useState([]);


  const filteredJobs = data?.filter((job) => {
    return job.title.toLowerCase().match(search.toLowerCase());
  });


  useEffect(() => {
    setAllData(data);
  }, [data]);

  useEffect(() => {
    setAllData(filteredJobs);
  }, [search]);

  function dataFiltering() {
    let locationArr = [];
    let departmentArr = [];
    let workTypeArr = [];

    data?.filter((items) => {
      if (items.location === location) {
        locationArr.push(items);
      }
    });

    if (location != "") {
      locationArr.filter((items) => {
        if (
          items.department.title?.split(" ")[0] === department.split(" ")[0]
        ) {
          departmentArr.push(items);
        } else if (items.jobType === workType) {
          workTypeArr.push(items);
        }
      });
      departmentArr.filter((items) => {
        if (items.jobType === workType) {
          workTypeArr.push(items);
        }
      });
    } else {
      data.filter((items) => {
        if (
          items.department.title?.split(" ")[0] === department.split(" ")[0]
        ) {
          departmentArr.push(items);
        }
      });
      departmentArr.filter((items) => {
        if (items.jobType === workType) {
          workTypeArr.push(items);
        }
      });
    }

    if (locationArr[0] != undefined && department === "" && workType === "") {
      setAllData(locationArr);
    } else if (
      locationArr[0] != undefined &&
      department != "" &&
      workType === ""
    ) {
      setAllData(departmentArr);
    } else if (
      locationArr[0] != undefined &&
      department != "" &&
      workType != ""
    ) {
      setAllData(workTypeArr);
    } else if (
      location[0] != undefined &&
      department === "" &&
      workType != ""
    ) {
      setAllData(workTypeArr);
    } else if (location === "" && workType === "" && department != "") {
      setAllData(departmentArr);
    } else if (location === "" && department != "" && workType != "") {
      setAllData(workTypeArr);
    } else if (location === "" && department === "" && workType != "") {
      data.filter((items) => {
        if (items.jobType === workType) {
          workTypeArr.push(items);
        }
      });
      setAllData(workTypeArr);
    } else {
      // setAllData([]);
    }
  }

  useEffect(() => {
    dataFiltering();
  }, [location, department, workType]);
 


  return (
    <div id="wrapper">
      <Header
        setLanguageToggle={setLanguageToggle}
        languageToggle={languageToggle}
      />
      <div id="main" className="content container">
        <section id="job-listings" className="cs-white-bg bottom-bordered">
          <div className="jobs-listings--inner">
            <div className="jobs-wrapper">
              <div className="job-listings--header">
                <h1 className="job-listings-title">Current Openings</h1>
                <p>
                  Thanks for checking out our job openings. See something that
                  interests you? Apply here.
                </p>
              </div>

              <div className="job-filters">
                <form action="" className="job-filter-form">
                  <div className="form-row pb-0">
                    <div className="form-col-100">
                      <div className="form-field py-1">
                        <label className="field-with-icon">
                          <img
                            src={require("../../../assets/img/brand/search.png")}
                            alt=""
                            className="field-icon"
                          />
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search Jobs"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-row pt-3">
                    <div className="form-col-28">
                      <div className="form-field no-pad">
                        <label>
                          <select
                            name=""
                            className="form-control"
                            id=""
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                          >
                            <option value="" selected disabled>
                              Location
                            </option>
                            <option>Riyadh</option>
                            <option>Khobar</option>
                            <option>Jeddah</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div className="form-col-28">
                      <div className="form-field no-pad">
                        <label>
                          <select
                            name=""
                            className="form-control"
                            id=""
                            value={department}
                            onChange={(e) => setDepartment(e.target.value)}
                          >
                            <option value="" selected disabled>
                              Department
                            </option>
                            <option>Richy fabric - أقمشة ريتشي</option>
                            <option>Supply chain - سلاسل الإمداد</option>
                            <option>IT - تقنية المعلومات</option>
                            <option>Inspiration - انسبايريشن</option>
                            <option>HR - الموارد البشرية</option>
                            <option>Markiting - التسويق</option>
                            <option>Lamode - لامود</option>
                            <option>Finance - المالية</option>
                            <option>Customer Care - العناية بالعملاء</option>
                            <option>Business Development -تطوير الأعمال</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div className="form-col-28">
                      <div className="form-field no-pad">
                        <label>
                          <select
                            name=""
                            className="form-control"
                            id=""
                            value={workType}
                            onChange={(e) => setWorkType(e.target.value)}
                          >
                            <option value="" selected disabled>
                              Work Type
                            </option>
                            <option>Full Time</option>
                            <option>Part Time</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div className="form-col form-col-auto no-pad">
                      <label className="toggle-switch-label">
                        <div
                          className="toggle-switch"
                          tabIndex="0"
                          role="switch"
                        >
                          <input
                            tabIndex="-1"
                            aria-hidden="true"
                            type="checkbox"
                            className=""
                          />
                          <span></span>
                        </div>
                        <span className="" id="checkbox_label_remote">
                          Remote only
                        </span>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
              
              <div>
                {allData[0] === undefined ?   (
                  <h1 className="noDataFound">No Job Available Currently!</h1>
                ) : (

                  <SingleJob
                    data={allData}
                    selectJobHandler={selectJobHandler}
                    languageToggle={languageToggle}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <div className=" cta-section" style={{ padding: "30px 15px 0px 15px" }}>
            <div className={"row align-items-center cs-white-bg p-lg-4 p-2 rounded-0 " + (languageToggle === 'english' ? '' : 'flex-row-reverse')}>
              <div className={"col-lg-8 col-12 mb-4 mb-lg-0 text-center " + (languageToggle === 'english' ? 'text-lg-left' : 'text-lg-right')}>
                <h4 className="job-listings-title">{languageToggle === "english" ? "Join us by applying your CV" : "انضم إلينا من خلال إرسال سيرتك الذاتية"}</h4>
                <p>
                  {
                    languageToggle === "english" ? "Apply your CV and we will keep you up to date with career opportunities that match your interests." :
                      "شاركنا سيرتك وسوف نبقيك على اطلاع دائم بالفرص الوظيفية التي تتناسب مع اهتماماتك."
                  }
                </p>
              </div>
              <div className="col-lg-4 col-12 text-center mb-3 mb-lg-0">
                <a
                  className="goto-apply-cv px-lg-5 px-3 py-lg-3 py-2 ml-0 ml-lg-3"
                  onClick={() => [
                    setGeneralJobSelected(true),
                    setdisplayViewJobDescriptonPanel(false),
                  ]}
                >
                  {languageToggle === "english" ? ("Apply your CV") : ("تقديم")}
                </a>
              </div>
            </div>
          </div>
      </div>
      
      <Footer
        setGeneralJobSelected={setGeneralJobSelected}
        setdisplayViewJobDescriptonPanel={setdisplayViewJobDescriptonPanel}
        languageToggle={languageToggle}
      />

    </div>
  );
};

export default Jobs;
