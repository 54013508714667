import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import React, { useState, useContext } from "react";
import axios from "axios";
import { endpointURL } from "../../../config/config";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const { setAuth, auth } = authContext;

  const history = useHistory();

  const renderError = (name) => {
    if (error[name]) {
      return (
        <Alert className="mt-3 cs-alert" color="danger">
          {error[name]}
        </Alert>
      );
    }
  };

  const loginHandler = async () => {
    try {
        setIsLoading(true);
        let formData = {email, password}
        if (email && password) {
            const { data } = await axios({
                method: "POST",
                url: `${endpointURL.serverUrl}/auth/login`,
                data: formData,
            });
            // const { data } = await axios.post(
            //   `${endpointURL.serverUrl}/auth/login`,
            //   {
            //     email,
            //     password,
            //   }
            // );

        console.log("Data ", data.data);
        localStorage.setItem("token", JSON.stringify(data.data));
        
        console.log("working");
        // setAuth(data.data);
        history.replace("/admin/index");
        
      } else {
          setIsLoading(false);
          let errors = {};
          if (!email) {
              errors["email"] = "Email is required!";
            }
            if (!password) {
            errors["password"] = "Password is required!";
            }
            setError(errors);
            console.log("Email & Password Required!");
      }
    } catch (error) {
        setIsLoading(false);
        console.log("Error: ", error);
        console.log("Error", error.response);
        if (!error?.response?.data.success) {
            setShowAlert(true);
            setAlertMessage(error?.response?.data.data);
        }
        console.log("Error", error?.response?.data);
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          {/* <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/github.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardHeader> */}
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup 
                    className="input-group-alternative" 
                    style={{
                        border: "1px solid transparent",
                        borderColor: renderError("email") ? "red" : "transparent",
                    }}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      borderColor: renderError("email") ? "red" : "black",
                    }}
                  />
                </InputGroup>
                {renderError("email")}
              </FormGroup>
              <FormGroup>
                <InputGroup 
                    className="input-group-alternative"
                    style={{
                        border: "1px solid transparent",
                        borderColor: renderError("email") ? "red" : "transparent",
                    }}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                      borderColor: renderError("password") ? "red" : "black",
                    }}
                  />
                </InputGroup>
                {renderError("password")}
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}

            {showAlert ?
            <Alert color="danger" className="mt-2 mb-0 py-2 pl-2">
                {/* <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "} */}
                <span className="alert-inner--text">
                  <strong>Error!</strong> {alertMessage}
                </span>
            </Alert>
            : ''}

              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={loginHandler}
                  disabled={isLoading? true : false}
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row> */}
      </Col>
    </>
  );
};

export default Login;
