import React, { useEffect, useRef, useState } from "react";
import Logo from "assets/img/brand/Richy_Group_Logo_1.png";



const Header = ({setLanguageToggle, languageToggle}) => {
   const [isChecked, setIsChecked]= useState(false)
  let togleRef = useRef(null);
  let togleStatus = localStorage.getItem("togleStatus");

  function toggleFunc(e){
    if(e.target.className === "english check-toggle check-toggle-round-flat"){
      e.target.className = "arabic check-toggle check-toggle-round-flat"
      setLanguageToggle("arabic")
      localStorage.setItem("togleStatus", "arabic");
    }else{
      e.target.className = "english check-toggle check-toggle-round-flat"
      setLanguageToggle("english")
      localStorage.setItem("togleStatus", "english");
    }
    
  }
  useEffect(()=>{
    if(togleRef.current.className === "arabic check-toggle check-toggle-round-flat"){
      localStorage.setItem("togleStatus", "arabic");
    }else{
      localStorage.setItem("togleStatus", "english");
    }
  },[])

  return (
    <>
    <header className="site-header">
        <div
          className="site-header--inner container"
          style={{ marginBottom: "10px" }}
        >
          <div className="flex-row header_row">
            <div className="logo">
              <img src={Logo}  onClick={()=>setLanguageToggle("arabic")} alt="" className="" />
            </div>
            <div className="lan_switch">
              <center>
                <div className="switch">
                    <input id="language-toggle" checked={togleStatus === "arabic" ? true : null} ref={togleRef} onClick={toggleFunc} className={`${togleStatus} check-toggle check-toggle-round-flat`}  type="checkbox"/>
                    <label for="language-toggle"></label>
                    <span className={"on"}  onClick={()=>setLanguageToggle("english")}>EN</span>
                    <span className={"off"} onClick={()=>setLanguageToggle("arabic")}>AR</span>
                </div>
              </center>
            </div>
          </div>
        </div>
    </header>
    </>
  );
};

export default Header;
