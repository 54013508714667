import React, { useEffect, useState } from "react";
import "../../../style.css";
import Logo from "../../../assets/img/brand/Richy_Group_Logo_1.png";
import validator from "validator";
import { Alert } from "reactstrap";
import axios from "axios";
import { endpointURL } from "../../../config/config";
import Footer from "components/Footers/Footer";
import Header from "components/Footers/Header";
import checkImg from "../../../images/check_icon.png";
import { useHistory } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const JobApply = ({
  data,
  setJobSelected,
  languageToggle,
  setLanguageToggle,
  displayViewJobDescriptonPanel,
  setGeneralJobSelected,
  setdisplayViewJobDescriptonPanel,
}) => {
  console.log(
    "🚀 ~ file: JobApply.js ~ line 21 ~ displayViewJobDescriptonPanel",
    displayViewJobDescriptonPanel
  );
  // state = {
  //   successMsg:true
  // }
  const serverUrl = endpointURL.imageUrl;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [nationality, setNationality] = useState("Saudi Arabia");
  const [qualification, setQualification] = useState("");
  const [startingYear, setStartingYear] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [experience, setExperience] = useState("");
  const [city, setCity] = useState("");
  const [currentJob, setCurrentJob] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [country, setCountry] = useState("Saudi Arabia");
  const [resume, setResume] = useState("");
  const [availableDate, setAvailableDate] = useState("");
  const [submitState, setSubmitState] = useState();
  const [successMsg, setsuccessMsg] = useState("");
  const [error, setError] = useState("");
  const [hintForNotice, setHintForNotice] = useState(false);
  let history = useHistory();

  const renderError = (name) => {
    if (error[name]) {
      return (
        <Alert className="mt-3" color="danger">
          {error[name]}
        </Alert>
      );
    }
  };


  const applyHandler = async (e) => {
    e.preventDefault();
    setError("");
    let errors = {};
    if (
      firstName &&
      lastName &&
      birthday &&
      nationality &&
      qualification &&
      mobileNo &&
      email &&
      experience &&
      city &&
      currentJob &&
      country &&
      resume &&
      availableDate &&
      institutionName
    ) {
      setSubmitState(false);
    
      if (validator.isEmail(email) && validator.isMobilePhone(mobileNo)) {
        let formData = new FormData();
        formData.append("application", resume);
        formData.append("job_id", data?._id);
        formData.append("fullname", `${firstName} ${lastName}`);
        formData.append("email", email);
        formData.append("birth_date", birthday);
        formData.append(
          "qualification",
          JSON.stringify([
            {
              school: institutionName,
              degree: qualification,
              start_date: startingYear,
            },
          ])
        );
        formData.append("mobile_number", mobileNo);
        formData.append("years_of_experience", experience);
        formData.append("expected_salary", "0"); /// remove and fix that okay issue
        formData.append("suggested_joining_date",availableDate);
        formData.append("city",city);
        formData.append("nationality",nationality);
        formData.append("country",country);
        formData.append("currentJob", currentJob);
        if (currentJob === "Yes") {
          formData.append("noticePeriod", noticePeriod);
        }


        try {
          // const { res } = await axios.post(`${endpointURL}/applications`,formData,{

          // })
          const { data } = await axios({
            method: "POST",
            url: `${endpointURL.serverUrl}/applications`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log("form response >>> ", data);
          if (data.success) {
            setSubmitState(true);
            setsuccessMsg(true);
          }
        } catch (error) {
          console.log("Error");
          console.log("Error", error.response);
          console.log("Error", error?.response?.data);
        }
      } else {
        console.log("Invalid email etc");
        if (!validator.isEmail(email)) {
          errors["email"] = "Email is invalid";
        }
        if (!validator.isMobilePhone(mobileNo)) {
          errors["mobileNo"] = "Mobile no is invalid";
        }
        console.log(errors);
        setError(errors);
      }
    } else {
      if (!firstName) {
        errors["firstName"] = "First Name is required";
      }
      if (!lastName) {
        errors["lastName"] = "Last Name is required";
      }
      if (!birthday) {
        errors["birthday"] = "Birthday is required";
      }
      if (!nationality) {
        errors["nationality"] = "Nationality is required";
      }
      if (!qualification) {
        errors["qualification"] = "Qualification is required";
      }
      if (!mobileNo) {
        errors["mobileNo"] = "Mobile no is required";
      }
      if (!email) {
        errors["email"] = "email is required";
      }
      if (!experience) {
        errors["experience"] = "Experience is required";
      }
      if (!city) {
        errors["city"] = "City is required";
      }
      if (!country) {
        errors["country"] = "Country is required";
      }
      if (!currentJob) {
        errors["currentJob"] = "Are you on Job is required";
      }
      if (!resume) {
        errors["resume"] = "Resume is required";
      }
      if (!availableDate) {
        errors["availableDate"] = "Availability date is required";
      }
      if (!institutionName) {
        errors["institutionName"] = "Institution name is required";
      }
      setError(errors);
    }
  };


  // Funtion to give Hint on Hover
  const hint = (value) => {
    setHintForNotice(value);
  };

  useEffect(() => {
    if (successMsg != false) {
      setTimeout(() => {
        history.push("/");
      }, 3000);
    }
  }, [successMsg]);

  return (
    <div id="wrapper">
      <Header setLanguageToggle={setLanguageToggle} />
      
      {successMsg ? (
        <>
          <div className="thanks_page_container">
            <div className="thank_page_content">
              <img src={checkImg} alt="check_icon.png" />
              <h4>THANK YOU</h4>
              <p>Form has been successfully submitted</p>
            </div>
          </div>
          <div className="redirecting_container">
            <p>Redirecting to the Job Opening .....</p>
          </div>
        </>
      ) : (
        <main id="main" className="content container">
          <section className="job-details">
            <div className="job-description cs-white-bg bottom-bordered">
              <div className="description-header">
                <div>
                  <p
                    className="back-to-jobs d-flex "
                    style={{ alignItems: "center", cursor: "pointer" }}
                  >
                    <i className="fas fa-arrow-left"></i>{" "}
                    {!displayViewJobDescriptonPanel ?

                      (<a onClick={() => history.push('/')}>Job Openings</a>)
                    :
                      (<a onClick={() => setJobSelected(null)}>Job Details</a>)
                    }
                  </p>
                  {languageToggle === "english" ? (
                    <h1 className="job-title">{data?.title}</h1>
                  ) : (
                    <h1 className="job-title">{data?.title_ar}</h1>
                  )}
                  <h6 className="job-meta">
                    {languageToggle === "english"
                      ? data?.department?.title + " - " + data?.location
                      : data?.department?.title_ar + " - " + data?.location}
                  </h6>
                </div>
                <div className="job-desc-logo">
                  <img
                    src={serverUrl + data.department?.imagePath}
                    alt=""
                    style={{ width: "140px", height: "130px" }}
                  />
                </div>
              </div>
              <div className="form-wrapper">
                <form className="application-form">
                  <div className="form-row">
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="firstName">First Name*</label>
                      ) : (
                        <label htmlFor="firstName" style={{ textAlign: "end" }}>
                          الاسم الاول*
                        </label>
                      )}
                      <input
                        type="text"
                        name=""
                        id="firstName"
                        className="form-control"
                        placeholder={languageToggle === "english" ? "First Name" : "الاسم الاول"}
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        style={{
                          borderColor: renderError("firstName")
                            ? "red"
                            : "",
                          textAlign:
                            languageToggle === "arabic" ? "end" : "start",
                        }}
                      />
                      {renderError("firstName")}
                    </div>
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="lastName">Last Name*</label>
                      ) : (
                        <label htmlFor="lastName" style={{ textAlign: "end" }}>
                          الاسم الأخير*
                        </label>
                      )}
                      <input
                        type="text"
                        name=""
                        id="lastName"
                        className="form-control"
                        placeholder={languageToggle === "english" ? "Last Name" : "الاسم الأخير"}
                        required
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        style={{
                          borderColor: renderError("lastName")
                            ? "red"
                            : "",
                          textAlign:
                            languageToggle === "arabic" ? "end" : "start",
                        }}
                      />
                      {renderError("lastName")}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="email">Date of Birth*</label>
                      ) : (
                        <label htmlFor="email" style={{ textAlign: "end" }}>
                          تاريخ الميلاد *
                        </label>
                      )}
                      <input
                        type="date"
                        name=""
                        id="dateAvailable"
                        className="form-control"
                        placeholder={languageToggle === "english" ? "Birthday" : "تاريخ الميلاد"}
                        value={birthday}
                        onChange={(e) => setBirthday(e.target.value)}
                        style={{
                          borderColor: renderError("birthday")
                            ? "red"
                            : "",
                          textAlign:
                            languageToggle === "arabic" ? "end" : "start",
                        }}
                      />
                      {renderError("birthday")}
                    </div>
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="email">Nationality*</label>
                      ) : (
                        <label htmlFor="email" style={{ textAlign: "end" }}>
                          الجنسية *
                        </label>
                      )}
                      <select
                        name=""
                        id="nationality"
                        className="form-control"
                        placeholder={languageToggle === "english" ? "Nationality" : "الجنسية"}
                        required
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                        style={{
                          borderColor: renderError("nationality")
                            ? "red"
                            : "",
                          textAlign:
                            languageToggle === "arabic" ? "end" : "start",
                        }}
                      >
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Territories">French Southern Territories</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                        <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                        <option value="Korea, Republic of">Korea, Republic of</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                        <option value="Moldova, Republic of">Moldova, Republic of</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">Russian Federation</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                        <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">Virgin Islands, British</option>
                        <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                        <option value="Wallis and Futuna">Wallis and Futuna</option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                      {renderError("nationality")}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="Academic">Academic qualification*</label>
                      ) : (
                        <label htmlFor="Academic" style={{ textAlign: "end" }}>
                          المؤهل العلمي *
                        </label>
                      )}
                      <select
                        name=""
                        id="qualification"
                        className="form-control"
                        required
                        value={qualification}
                        onChange={(e) => setQualification(e.target.value)}
                        style={{
                          borderColor: renderError("qualification")
                            ? "red"
                            : "",
                          textAlign:
                            languageToggle === "arabic" ? "end" : "start",
                        }}
                      >
                        <option aria-readonly={true}>Select</option>
                        <option>High School Degree - ثانوي</option>
                        <option>Bachelor Degree - بكالوريس</option>
                        <option>Master's Degree - ماستر</option>
                        <option>Doctoral Degree - دكتوراة</option>
                        <option>Others - أخرى</option>
                      </select>
                      {renderError("qualification")}
                    </div>
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="qualification">Major*</label>
                      ) : (
                        <label
                          htmlFor="qualification"
                          style={{ textAlign: "end" }}
                        >
                          التخصص*
                        </label>
                      )}
                      <input
                        type="text"
                        name=""
                        id="qualification"
                        className="form-control"
                        placeholder={languageToggle === "english" ? "Major" : "التخصص"}   //"اسم المعهد"
                        value={institutionName}
                        onChange={(e) => setInstitutionName(e.target.value)}
                        style={{
                          borderColor: renderError("graduationYear")
                            ? "red"
                            : "",
                          textAlign:
                            languageToggle === "arabic" ? "end" : "start",
                        }}
                      />
                      {renderError("graduationYear")}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="phone">Mobile Number*</label>
                      ) : (
                        <label htmlFor="phone" style={{ textAlign: "end" }}>
                          رقم الجوال*
                        </label>
                      )}
                      <input
                        type="tel"
                        name=""
                        id="phone"
                        className="form-control"
                        placeholder={languageToggle === "english" ? "Mobile" : "رقم الجوال"}
                        required
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                        style={{
                          borderColor: renderError("mobileNo")
                            ? "red"
                            : "",
                          textAlign:
                            languageToggle === "arabic" ? "end" : "start",
                        }}
                      />
                      {renderError("mobileNo")}
                    </div>
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="email">Email*</label>
                      ) : (
                        <label htmlFor="email" style={{ textAlign: "end" }}>
                          البريد الإلكتروني *
                        </label>
                      )}
                      <input
                        type="email"
                        name=""
                        id="email"
                        className="form-control"
                        placeholder={languageToggle === "english" ? "Email" : " البريد الإلكتروني"}
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          borderColor: renderError("email") ? "red" : "",
                          textAlign:
                            languageToggle === "arabic" ? "end" : "start",
                        }}
                      />
                      {renderError("email")}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="experience">Years of experience*</label>
                      ) : (
                        <label
                          htmlFor="experience"
                          style={{ textAlign: "end" }}
                        >
                          سنوات الخبرة*
                        </label>
                      )}
                      <select
                        
                        name=""
                        id="experience"
                        className="form-control"
                        placeholder={languageToggle === "english" ? "Experience" : "سنوات الخبرة"}
                        required
                        value={experience}
                        onChange={(e) => setExperience(e.target.value)}
                        style={{
                          borderColor: renderError("experience")
                            ? "red"
                            : "",
                          textAlign:
                            languageToggle === "arabic" ? "end" : "start",
                        }}
                      >
                        <option >select</option>
                        <option value="No experience">No experience</option>
                        <option value="1-2">1-2</option>
                        <option value="3-5">3-5</option>      
                        <option value="More than 5">More than 5</option>                  
                      </select>
                      {renderError("experience")}
                    </div>
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="country">Country*</label>
                      ) : (
                        <label htmlFor="country" style={{ textAlign: "end" }}>
                          بلد*
                        </label>
                      )}
                      <select
                        name=""
                        id="country"
                        className="form-control"
                        placeholder={languageToggle === "english" ? "Country" : "بلد"}
                        required
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        style={{
                          borderColor: renderError("country") ? "red" : "",
                          textAlign:
                            languageToggle === "arabic" ? "end" : "start",
                        }}
                      >
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Territories">French Southern Territories</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                        <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                        <option value="Korea, Republic of">Korea, Republic of</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                        <option value="Moldova, Republic of">Moldova, Republic of</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">Russian Federation</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                        <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">Virgin Islands, British</option>
                        <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                        <option value="Wallis and Futuna">Wallis and Futuna</option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                      {renderError("country")}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="city">City*</label>
                      ) : (
                        <label htmlFor="city" style={{ textAlign: "end" }}>
                          مدينة*
                        </label>
                      )}
                      <input
                        type="text"
                        name=""
                        id="city"
                        className="form-control"
                        placeholder={languageToggle === "english" ? "City" : "مدينة"}
                        required
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        style={{
                          borderColor: renderError("city") ? "red" : "",
                          textAlign:
                            languageToggle === "arabic" ? "end" : "start",
                        }}
                      />
                      {renderError("city")}
                    </div>
                    <div className="form-group form-col-50">
                      {languageToggle === "english" ? (
                        <label htmlFor="currentJob">
                          Are you currently on employed?*
                        </label>
                      ) : (
                        <label
                          htmlFor="currentJob"
                          style={{ textAlign: "end" }}
                        >
                         هل أنت على رأس العمل ؟*
                        </label>
                      )}
                      <div
                        style={{ display: "flex", alignItems: "flex", flex: 1 , flexDirection:(languageToggle === "english" ? "row" : "row-reverse") }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: (languageToggle === "english" ? "22%" : "15%"),
                          }}
                        >
                          <input
                            type="radio"
                            name="currentJob"
                            id="currentJobYes"
                            className="form-control"
                            required
                            value="Yes"
                            onChange={() => setCurrentJob("Yes")}
                            style={{ fontSize: "12px" }}
                          />
                          <label
                            for="currentJobYes"
                            style={{ marginTop: "12px" ,marginRight:(languageToggle === "english" ?"18px" : "0px")}}
                          >
                            {languageToggle === "english" ? ("Yes") : ("نعم")}
                          </label>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: (languageToggle === "english" ? "22%" : "15%"),
                          }}
                        >
                          <input
                            type="radio"
                            name="currentJob"
                            id="currentJobNo"
                            className="form-control"
                            required
                            value="No"
                            onChange={() => setCurrentJob("No")}
                          />
                          <label
                            for="currentJobNo"
                            style={{ marginTop: "12px",marginRight:(languageToggle === "english" ?"18px" : "0px") }}
                          >
                            {languageToggle === "english" ? ("No") : ("لا")}
                          </label>
                        </div>
                      </div>
                      {renderError("currentJob")}
                    </div>
                  </div>
                  {currentJob == "Yes" && (
                    <div className="form-row cs-multi-field" style={{flexDirection:(languageToggle === "english" ? "row" : "row-reverse")}}>
                      <div className="form-group form-col-50">
                        {languageToggle === "english" ? (
                          <label htmlFor="noticePeriod">
                            The Notice Period*
                            <i
                              class="fa  fa-question"
                              onMouseEnter={() => {
                                hint(true);
                              }}
                              onMouseLeave={() => {
                                hint(false);
                              }}
                              style={{
                                borderRadius: "50%",
                                padding: 8,
                                fontSize: "9px",
                                cursor: "pointer",
                                marginLeft: "8px",
                                color: "#fff",
                                backgroundColor: "#bb853d",
                              }}
                            />
                            {/* <FontAwesomeIcon icon="fa-solid fa-question" /> */}
                          </label>
                        ) : (
                          <label
                            htmlFor="noticePeriod"
                            style={{ textAlign: "end" }}
                          >
                           <i
                              class="fa  fa-question"
                              onMouseEnter={() => {
                                hint(true);
                              }}
                              onMouseLeave={() => {
                                hint(false);
                              }}
                              style={{
                                borderRadius: "50%",
                                padding: 8,
                                fontSize: "9px",
                                cursor: "pointer",
                                marginLeft: "8px",
                                color: "#fff",
                                backgroundColor: "#bb853d",
                              }}
                            />
                            فترة إشعار*
                          </label>
                        )}
                        <input
                          type="text"
                          name=""
                          id="noticePeriod"
                          className="form-control"
                          value={noticePeriod}
                          onChange={(e) => setNoticePeriod(e.target.value)}
                          style={{
                            borderColor: renderError("noticePeriod")
                              ? "red"
                              : "",
                            textAlign:
                              languageToggle === "arabic" ? "end" : "start",
                          }}
                        />
                        
                        {hintForNotice && (
                          <p style={{ marginTop: "12px" }}>
                            {languageToggle === "english"? (
                              "The period of time required to remain in employment in the event of a resignation!"
                            ):(
                              "لفترة الزمنية اللازمة للبقاء في العمل في حالة تقديم الاستقالة"
                            )}
                          </p>
                        )}
                        {renderError("noticePeriod")}
                      </div>
                    </div>
                  )}
                  <div className="form-row"></div>
                  <div className="form-section">
                    <div className="form-row" style={{flexDirection:(languageToggle === "english" ? "row" : "row-reverse")}}>
                      <div className="form-group form-col-30">
                        {languageToggle === "english" ? (
                          <label htmlFor="file-upload">Resume*</label>
                        ) : (
                          <label
                            htmlFor="file-upload"
                            style={{ textAlign: "end" }}
                          >
                            سيرة ذاتية*
                          </label>
                        )}
                        <br />
                        <label
                          htmlFor="file-upload"
                          className="custom-file-upload"
                          style={{float:(languageToggle === "english" ? "none" : "right")}}
                        >
                          {languageToggle === "english" ? ("Choose File") : ("اختر ملف")}
                          <input
                            id="file-upload"
                            type="file"
                            multiple={false}
                            // value={resume}
                            accept=".doc,.docx,.pdf"
                            onChange={(e) => setResume(e.target.files[0])}
                          />
                        </label>
                        <p className="mt-2">{resume?.name}</p>
                        {renderError("resume")}
                      </div>
                    </div>
                    <div className="form-row" style={{flexDirection:(languageToggle === "english" ? "row" : "row-reverse")}}>
                      <div className="form-group form-col-50">
                        {languageToggle === "english" ? (
                          <label htmlFor="file-upload">Suggested Joining Date*</label>
                        ) : (
                          <label htmlFor="email" style={{ textAlign: "end" }}>
                            التاريخ المتاح *
                          </label>
                        )}
                        <input
                          type="date"
                          min={new Date().toISOString().split('T')[0]}
                          name=""
                          id="dateAvailable"
                          className="form-control"
                          value={availableDate}
                          onChange={(e) => setAvailableDate(e.target.value)}
                          style={{
                            textAlign:
                              languageToggle === "arabic" ? "end" : "start",
                          }}
                        />
                        {renderError("availableDate")}
                      </div>
                    </div>
                    <div className="form-row"></div>
                  </div>
                  {/* <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="lastSalary">
                      What is your salary at your last job?*
                    </label>
                    <input
                      type="text"
                      name=""
                      id="lastSalary"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="availability">
                      When would you be available to start?*
                    </label>
                    <input
                      type="text"
                      name=""
                      id="availability"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="expectedSalary">
                      What is your expected salary?*
                    </label>
                    <input
                      type="text"
                      name=""
                      id="expectedSalary"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="experience">
                      How many years of experience do you have?*
                    </label>
                    <input
                      type="number"
                      name=""
                      id="experience"
                      className="form-control"
                    />
                  </div>
                </div> */}
                  <div className="form-actions" style={{flexDirection:(languageToggle === "english" ? "row" : "row-reverse")}}>
                    {submitState === false ? (
                      <button class="theme-button">
                        <i class="fa fa-circle-o-notch fa-spin"></i>Loading
                      </button>
                    ) : (
                      <input
                        type="submit"
                        className="theme-button"
                        value={languageToggle === "english" ? ("Submit") : ("إرسال")}
                        onClick={applyHandler}
                      />
                    )}
                    <a href="job-detail.html" className="cancel-link" style={{marginRight:(languageToggle==="english" ? "0px" : "20px")}}>
                      {(languageToggle === "english") ? "Cancel" : "إلغاء"}
                    </a>
                    {successMsg ? (
                      <Alert color="success">
                        <span className="alert-inner--icon">
                          <i className="ni ni-like-2" />
                        </span>{" "}
                        <span className="alert-inner--text">
                          <strong>Success!</strong> Form Submission!
                        </span>
                      </Alert>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>


            {displayViewJobDescriptonPanel && (
              <aside className="job-sidebar ml-3 sm-hide">
                <div className="grey-bg cs-white-bg">
                  <div className="actions">
                    <p
                      className="theme-button apply-btn"
                      onClick={() => setJobSelected(false)}
                    >
                      View Job Description
                    </p>
                  </div>
                  <div className="job-link">
                    <p>Link to this job</p>
                    <input
                      type="text"
                      id="jobLink"
                      className="form-control"
                      value={`https://careers.richy.group/index/job?id=${data?._id}`}
                      readOnly
                      onClick={() => console.log("Clicked")}
                    />
                  </div>
                  <div className="social-links">
                    <a href="https://www.linkedin.com/company/saudi-richy">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                    <a href="https://twitter.com/SaudiRichy" className="ml-2">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </div>
                </div>
                <div className="job-meta-list">
                  <ul>
                    <li>
                      <p>
                        <span>Location</span>
                        {data?.location}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>Department</span>
                        {data?.department?.title}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>Employment Type</span>
                        {data?.jobType}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>Minimum Experience</span>{data?.experience} Years
                      </p>
                    </li>
                  </ul>
                </div>
              </aside>
            )}
          </section>
        </main>
      )}
      
      <Footer
        setGeneralJobSelected={setGeneralJobSelected}
        setdisplayViewJobDescriptonPanel={setdisplayViewJobDescriptonPanel}
        languageToggle={languageToggle}
      />
    </div>
  );
};

export default JobApply;
