import { convertFromRaw, EditorState } from "draft-js";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../../style.css";

// reactstrap components
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { endpointURL } from "../../../config/config";

function JobDetailModal({ modalOpen, setModalOpen, data }) {
  return (
    <>
      <Modal
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
        // wrapClassName="modalJob"
        className="modal-lg"
        // modalClassName="modalJob"
        // contentClassName="modalJob"
      >
        <div className="modal-header py-3 bg-white border-bottom">
          <h6 className="font-weight-bold" id="exampleModalLabel">
            Job Detail
          </h6>
          <button
            aria-label="Close"
            className="close py-0 my-0"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody className="bg-secondary py-0">
          <section className="job-details">
            <div className="job-description">
              <div className="description-header">
                  <div className="mb-2">
                    {/* <p
                      className="back-to-jobs"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <i className="fas fa-arrow-left"></i>
                      {"  "}
                      <p
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        onClick={() => setSelectedJob(null)}
                      >
                        Job Openings
                      </p>
                    </p> */}
                    <h1 className="">{data?.title}</h1>
                    <ul className="p-0 d-inline-flex mb-0">
                      <li className="list-unstyled pr-2 mb-0"><i class="ni ni-briefcase-24 mr-2"></i>{data?.department?.title}</li>
                      <li className="list-unstyled text-black pl-2 mb-0"><i class="ni ni-pin-3 mr-1"></i>{data?.location}</li>
                    </ul>
                    {/* <h6 className="job-meta">{data?.department?.title}</h6>
                    <h6 className="job-meta"><i class="ni ni-pin-3"> </i> {data?.location}</h6> */}
                  </div>
                  <div className="job-desc-logo">
                    <img
                      src={endpointURL.imageUrl+data?.department?.imagePath}
                      alt=""
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                </div>
                <div className="description-bod">
                  <h5 className="mt-4">Job Description</h5>
                  <p className="description-text">
                  {data?.description}
                  </p>
                  {/* <Editor
                    toolbarHidden
                    readOnly
                    editorClassName="editorStyles"
                    editorState={EditorState.createWithContent(
                      convertFromRaw(JSON.parse(data?.description))
                    )}
                  /> */}
                  <h5 className="mt-4">Job Key Requirements</h5>
                  <p className="description-text">
                  {data?.requirements}
                  </p>
                  {/* <Editor
                    toolbarHidden
                    readOnly
                    editorClassName="editorStyles"
                    editorState={EditorState.createWithContent(
                      convertFromRaw(JSON.parse(data?.requirements))
                    )}
                  /> */}
                </div>
            </div>
          </section>
          {/* <main id="main" className="content container">
            
          </main> */}
        </ModalBody>
        <ModalFooter className="py-2 bg-white border-top">
          <Button
            color="danger"
            type="button"
            className="btn-sm"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default JobDetailModal;
