import React, { createContext, useState, useEffect } from "react";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Logo from "../assets/img/brand/argon-react.png";

import routes from "routes.js";
import PrivateRoute from "views/pages/Admin/PrivateRoute";

export const AuthContext = createContext();

const Admin = (props) => {
  const history = useHistory();
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [auth, setAuth] = useState(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    console.log("Wokring token");
    let token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      console.log("Token found");
      setAuth(token);
    } else {
      console.log("token not found");
      setAuth(false);
    }
  }, [history]);

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: Logo,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {auth !== null && getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid className="mt-5 admin-footer">
          <AdminFooter />
        </Container>
      </div>
    </AuthContext.Provider>
  );
};

export default Admin;
