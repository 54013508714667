import React, { useState, useContext } from "react";
import "../../../style.css";
import Header from "components/Headers/Header.js";
import {
  Container,
  Button,
  Alert,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
import { AuthContext } from "layouts/Admin";
import axios from "axios";
import { endpointURL } from "../../../config/config";

function Settings() {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [error, setError] = useState("");
    const [showSuccess, setShowSuccess] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { auth, setAuth } = useContext(AuthContext);
    
    const renderError = (name) => {
        if (error[name]) {
            return (
                <Alert className="mt-3" color="danger">
                    {error[name]}
                </Alert>
            );
        }
    };
    
    // const renderSuccess = (name) => {
    //     if (success[name]) {
    //         return (
    //             <Alert className="mt-3" color="success">
    //                 {success[name]}
    //             </Alert>
    //         );
    //     }
    // };
    const updatePasswordHandler = async () => {
        setError("")
        setIsLoading(true)
        let token = auth?.token
        let user_id = auth?.user._id
        let errors = {}
        console.log("User ID: ", user_id)

        if (
            password &&
            newPassword &&
            confirmNewPassword
        ) {
            if (newPassword == confirmNewPassword) {
            try {
                console.log("Here");
                    const { data } = await axios({
                        method: "POST",
                        url: `${endpointURL.serverUrl}/auth/update-password`,
                        data: {
                            user_id: user_id,
                            password: password,
                            newPassword: newPassword
                        },
                    },{
                        headers: {
                            "x-access-token": token,
                        }
                    });

                    console.log("Data: ", data)
                    
                    // if (data.data.success) {
                        setShowSuccess(true)
                        setSuccessMessage("Password updated successfully !")
                        setShowAlert(false)
                    // }
                } catch (error) {
                    // console.log("lalla")
                    // if (!error?.response?.data?.success) {
                        setShowAlert(true)
                        setIsLoading(false)
                        console.log("Error: ", error?.response?.data)
                        setAlertMessage(error?.response?.data.error)
                    // }
                }
            } else {
                setIsLoading(false)
                setShowAlert(true)
                setAlertMessage("New password & Confirm password must be same !")
            }
        } else {
            if (!password) {
                errors["password"] = "Password is required !"
            }
            if (!newPassword) {
                errors["newPassword"] = "New Password is required !"
            }
            if (!confirmNewPassword) {
                errors["confirmNewPassword"] = "Confirm New Password is required !"
            }

            setIsLoading(false)
            setError(errors)
        }
        
    };

  return (
    <>
      <Header hidden={true} />
      {/* Page content */}
      <Container className="mt--8 cs-setting-page-height" fluid>
        <div className="col-xl-8">
          <div className="bg-secondary shadow card">
            <div className="card-body">
              <form className="admin-form">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label
                        className="form-control-label mb-2"
                        for="input-username"
                      >
                        Current Pasword
                      </label>
                      <input
                        placeholder="Curent Password"
                        type="password"
                        autoComplete="title"
                        onChange={(e) => setPassword(e.target.value)}
                        style={{
                          borderColor: renderError("password")
                            ? "#ff0000!important"
                            : "#cad1d7",
                        }}
                        className="form-control-alternative form-control admin-field-border"
                      />
                      {renderError("password")}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label
                        className="form-control-label mb-2"
                        for="input-username"
                      >
                        New Password
                      </label>
                      <input
                        placeholder="New Password"
                        type="password"
                        autoComplete="newPassword"
                        onChange={(e) => setNewPassword(e.target.value)}
                        style={{
                          borderColor: renderError("newPassword")
                            ? "#ff0000!important"
                            : "#cad1d7",
                        }}
                        className="form-control-alternative form-control admin-field-border"
                      />
                      {renderError("newPassword")}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label
                        className="form-control-label mb-2"
                        for="input-username"
                      >
                        Confirm New Password
                      </label>
                      <input
                        placeholder="Confirm New Password"
                        type="password"
                        autoComplete="confirm_new_password"
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        style={{
                          borderColor: renderError("confirmNewPassword")
                            ? "#ff0000!important"
                            : "#cad1d7",
                        }}
                        className="form-control-alternative form-control admin-field-border"
                      />
                      {renderError("confirmNewPassword")}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-12">
                    <Button
                      className="btn-cs w-100"
                      color="primary"
                      type="button"
                      onClick={updatePasswordHandler}
                      disabled={isLoading? true : false}
                    >
                      {"Update Password"}
                    </Button>
                  </div>
                </div>
                {
                    showAlert ?
                    <Alert color="danger" className="mt-2 mb-0 py-2 pl-2 cs-admin-alert">
                        <span className="alert-inner--text">
                            <strong>Error!</strong> {alertMessage}
                        </span>
                    </Alert>
                    : ''
                }
                {
                    showSuccess ?
                    <Alert color="success" className="mt-2 mb-0 py-2 pl-2 cs-admin-alert">
                        <strong>Success!</strong> {successMessage}
                    </Alert>
                    : ''
                }
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Settings;
