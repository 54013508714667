import React from "react";
import { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import axios from "axios";
import { endpointURL } from "../../../config/config";
import DataTable  from "../../../components/DataTable"

const Index = (props) => {
    const [activeNav, setActiveNav] = useState(1);
    const [chartExample1Data, setChartExample1Data] = useState("data1");
    const [applicationsData, setApplicationsData] = useState([]);
    const [applicationsCount, setApplicationsCount] = useState(0);

    const toggleNavs = (e, index) => {
        e.preventDefault();
        setActiveNav(index);
        setChartExample1Data("data" + index);
    };

    const getAllAplications = async () => {
        try {
            const applications = await axios.get(`${endpointURL.serverUrl}/applications/`);
            console.log("tets", applications);
            if (applications.data.success) {
                console.log("Applications Count: ", applications.data.data.length)
                setApplicationsCount(applications.data.data.length)
                console.log("Applicaitons Data: ", applications.data.data)
                setApplicationsData(applications.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllAplications();
      }, []);

    // useEffect(() => {
    //     console.log(
    //       "Occurs ONCE, AFTER the initial render."
    //     );
    //   }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'CANDIDATE NAME',
                accessor: 'name',
            },
            {
                Header: 'EMAIL',
                accessor: 'email'
            },
            {
                Header: 'PHONE',
                accessor: 'mobile_number'
            },
            {
                Header: 'EXPERIENCE',
                accessor: 'years_of_experience'
            },
            {
                Header: 'RESUME',
                accessor: 'resume_file',
                disableFilters:true
            },
        ],
        []
    )

  return (
    <>
        <Header 
            applicationsCount={applicationsCount}
            hidden={false} />
        {/* Page content */}
        <Container className="mt--8" fluid>
            <Row className="mt-5">
                <Col className="mb-5 mb-xl-0" xl="12">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">All Applicants</h3>
                            </div>
                            <div className="col text-right">
                                <Button
                                color="primary"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                size="sm"
                                >
                                See all
                                </Button>
                            </div>
                            </Row>
                        </CardHeader>

                        <DataTable columns={columns} data={applicationsData} filters={["name", "email"]} />
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
  );
};

export default Index;
