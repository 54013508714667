import React, { useEffect, useState } from "react";
import "../../style.css";
import UserPic from "../../assets/img/brand/users.png";
import MapMarker from "../../assets/img/brand/maps-marker.png";
import { endpointURL } from "../../config/config";

const SingleJob = ({ data, selectJobHandler, languageToggle }) => {
  let [timeState, setTimeState] = useState();


  
  function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  function daysDiff(d1, d2) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  function dateFunc(d1, d2) {
    var diff = monthDiff(d1, d2);
    if (diff == 0) {
      return daysDiff(d1, d2);
    } else {
      setTimeState(diff);
      return diff;
    }
  }

  const serverUrl = endpointURL.imageUrl;

  return (
    <div className="jobs-list">
      <ul className="jobs">
        {data?.map((job, i) => {
          if (job.isActive) {
            return (
              <li
                className=""
                key={i}
                onClick={() => selectJobHandler(job)}
                style={{ cursor: "pointer" }}
              >
                <div className="job-header">

                  {languageToggle === "english" ? (
                    <small className="job-department">
                      {job?.department?.title}
                    </small>
                  ) : (
                    <small className="job-department">
                      {job?.department?.title_ar}
                    </small>
                  )}

                  <h3 className="job-title" aria-multiline={false}>
                    {languageToggle === "english" ? (
                      <span>{job?.title}</span>
                    ) : (
                      <span>{job?.title_ar}</span>
                    )}
                  </h3>
                  <small className="job-posted">
                    Posted {dateFunc(new Date(), new Date(job?.updatedAt))}{" "}
                    {timeState === undefined ? "Days" : "Month"} ago
                  </small>
                </div>
                
                <span className="job-brand">
                    <img
                        src={serverUrl + job.department?.imagePath}
                        alt=""
                        style={{ width: "90px", height: "80px" }}
                      />
                </span>

                <span className="job-type">
                  <span>
                    <img src={UserPic} alt="" /> {job?.jobType}
                  </span>
                </span>

                <span className="job-location">
                  <span>
                    <img src={MapMarker} alt="" /> {job?.location}
                  </span>
                </span>
                
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default SingleJob;
