import React from "react";

// reactstrap components
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function DeleteModal({ modalOpen, setModalOpen, confirmDeleteHandler }) {
  return (
    <>
      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="exampleModalLabel">
            Are you sure you want to delete?
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Cancel
          </Button>
          <Button color="primary" type="button" onClick={confirmDeleteHandler}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default DeleteModal;
