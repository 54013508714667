import React, { useState, useEffect } from "react";
import JobApply from "./JobApply";
import JobDetails from "./JobDetails";
import Jobs from "./Jobs";
import axios from "axios";
import { useParams } from "react-router-dom";
import { endpointURL } from "../../../config/config";

const generalData = {
  _id: "6320fc2834d07c49b0d16b82",    // for general Appliccations
  title: "Join Us by Applying Your CV",
  title_ar: "انضم إلينا من خلال إرسال سيرتك الذاتية",
  description: "description",
  description_ar: "arabic descrition",
  requirements: "",
  requirements_ar: "",
  isActive: false,
  department: {
    _id: "general",
    title: "we will keep you up to date with career opportunities that match your interests.",
    title_ar:"شاركنا سيرتك وسوف نبقيك على اطلاع دائم بالفرص الوظيفية التي تتناسب مع اهتماماتك.",
    imagePath: "departments/richy_logo.png",
    createdAt: "2022-02-26T15:19:40.486Z",
    __v: 0,
  },
  location: "",
  jobType: "Full Time",
  updatedAt: "2022-07-17T08:04:39.129Z",
  createdAt: "2022-07-17T08:04:39.129Z",
  __v: 0,
};

function Index() {
  const param = useParams();
  const [data, setData] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobSelected, setJobSelected] = useState(null);
  const [generaljobSelected, setGeneralJobSelected] = useState(false);
  const [displayViewJobDescriptonPanel, setdisplayViewJobDescriptonPanel] =
    useState(true);
  const [languageToggle, setLanguageToggle] = useState("english");

  const renderPages = () => {
    if (selectedJob && jobSelected) {
      return (
        <JobApply
          data={selectedJob}
          setJobSelected={setJobSelected}
          languageToggle={languageToggle}
          setLanguageToggle={setLanguageToggle}
          displayViewJobDescriptonPanel={displayViewJobDescriptonPanel}
          setGeneralJobSelected={setGeneralJobSelected}
          setdisplayViewJobDescriptonPanel={setdisplayViewJobDescriptonPanel}
        />
      );
    } else if (generaljobSelected) {
      return (
        <JobApply
          data={generalData}
          setJobSelected={setJobSelected}
          languageToggle={languageToggle}
          setLanguageToggle={setLanguageToggle}
          displayViewJobDescriptonPanel={displayViewJobDescriptonPanel}
          setGeneralJobSelected={setGeneralJobSelected}
          setdisplayViewJobDescriptonPanel={setdisplayViewJobDescriptonPanel}
        />
      );
    } else if (selectedJob) {
      return (
        <JobDetails
          data={selectedJob}
          setJobSelected={setJobSelected}
          setSelectedJob={setSelectedJob}
          setLanguageToggle={setLanguageToggle}
          languageToggle={languageToggle}
          setGeneralJobSelected={setGeneralJobSelected}
          setdisplayViewJobDescriptonPanel={setdisplayViewJobDescriptonPanel}
        />
      );
    } else {
      return (
        <Jobs
          setLanguageToggle={setLanguageToggle}
          languageToggle={languageToggle}
          data={data}
          selectJobHandler={selectJobHandler}
          setGeneralJobSelected={setGeneralJobSelected}
          setdisplayViewJobDescriptonPanel={setdisplayViewJobDescriptonPanel}
        />
      );
    }
  };

  const selectJobHandler = (data) => {
    setSelectedJob(data);
  };

  const getJobs = async () => {
    try {
      const { data } = await axios.get(`${endpointURL.serverUrl}/jobs/`);
      console.log(data);
      setData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  return <div>{renderPages()}</div>;
}

export default Index;
