import React, { useState } from "react";
import "../../../style.css";
import Header from "components/Headers/Header.js";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import PagePagination from "components/PagePagination/PagePagination";
import { endpointURL } from "../../../config/config";

const Jobs = ({ data, selectJobHandler, deleteJobHandler }) => {
  console.log("job listing >>>", data);

  const [search, setSearch] = useState("");

  const history = useHistory();

  const filterData = data.filter((job) =>
    job.title.toLowerCase().match(search.toLowerCase())
  );

  console.log("filterdata >", filterData);
  return (
    <>
      <Header hidden={true} />
      {/* Page content */}
      <Container className="mt--8" fluid>
        {/* <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Sales value</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart 
                <div className="chart">
                  <Line
                    data={chartExample1[chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Total orders</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart 
                <div className="chart">
                  <Bar
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row> */}

        <Row className="mt-5 mb-5 px-3">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">All Jobs</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/admin/createjob");
                      }}
                      size="sm"
                    >
                      Add Job
                    </Button>
                  </div>
                </Row>
                <Row style={{ marginTop: "40px" }}>
                  <Col>
                    <FormGroup>
                      <InputGroup className="input-group-alternative  px-3">
                        <InputGroupAddon addonType="prepend"></InputGroupAddon>
                        <Input
                          placeholder="Search"
                          type="text"
                          autoComplete="title"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-flush"
                style={{ tableLayout: "fixed", whiteSpace: "normal" }}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ width: "30%" }}>
                      Title
                    </th>
                    <th scope="col">Location</th>
                    <th scope="col">Work Type</th>
                    <th scope="col">Department</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filterData.map((job, i) => {
                    if (job.isActive) {
                      return (
                        <tr key={i}>
                          <th
                            scope="row"
                            onClick={() => selectJobHandler(job)}
                            style={{
                              cursor: "pointer",
                              whiteSpace: "normal",
                              width: "60%",
                            }}
                          >
                            {job.title}
                          </th>
                          <td style={{ whiteSpace: "normal" }}>{job.location}</td>
                          <td style={{ whiteSpace: "normal" }}>{job.jobType}</td>
                          <td style={{ whiteSpace: "normal" }}>
                            {job.department.title}
                            <img
                              className="ml-2"
                              src={endpointURL.imageUrl+job?.department?.imagePath}
                              width="50px"
                              height="50px"
                            />
                          </td>
                          <th scope="row" style={{ whiteSpace: "normal" }}>
                            {job.isActive === true ? (<span class='badge bg-success text-dark'>Active</span>) : (<span class='badge bg-warning text-dark'>Unactive</span>)}
                          </th>
                          <th
                            scope="row"
                            style={{ cursor: "pointer", whiteSpace: "normal" }}
                          >
                            <UncontrolledDropdown>
                              <DropdownToggle
                                style={{
                                  background: "none",
                                  boxShadow: "none",
                                  border: "none",
                                }}
                              >
                                <i className="fas fa-ellipsis-h" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => selectJobHandler(job)}
                                >
                                  <i className="fas fa-eye  mr-3" /> View Detail
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    history.push({
                                      pathname: "/admin/editjob",
                                      state: { data: job },
                                    })
                                  }
                                >
                                  <i className="fas fa-pen  mr-3" />
                                  Edit Job
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => deleteJobHandler(job._id)}
                                >
                                  <i className="fas fa-trash  mr-3" />
                                  Delete Job
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </th>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end mr-5">
          <PagePagination />
        </Row>
      </Container>
    </>
  );
};

export default Jobs;
