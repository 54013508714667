import Dashboard from "views/pages/Admin/Dashboard.js";
import Settings from "./views/pages/Admin/Settings.js";
import Login from "./views/pages/Admin/Login.js";
import JobPage from "./views/pages/Admin/Index";
import UserJobPage from "./views/pages/User/Index";
import JobApply from "views/pages/User/JobApply";
import CreateJob from "views/pages/Admin/CreateJob";
import EditJob from "views/pages/Admin/EditJob";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  // },

  {
    path: "/jobs",
    name: "Jobs",
    icon: "ni ni-bullet-list-67 text-red",
    component: JobPage,
    layout: "/admin",
  },
  {
    path: "/createjob",
    name: "Create Job",
    icon: "ni ni-bullet-list-67 text-red",
    component: CreateJob,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/editjob",
    name: "Edit Job",
    icon: "ni ni-bullet-list-67 text-red",
    component: EditJob,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/jobs",
    name: "Jobs",
    icon: "ni ni-bullet-list-67 text-red",
    component: UserJobPage,
    layout: "/index",
    hidden: true,
  },
  {
    path: "/jobs/:id",
    name: "Jobs",
    icon: "ni ni-bullet-list-67 text-red",
    component: UserJobPage,
    layout: "/index",
    hidden: true,
  },
  {
    path: "/settings",
    name: "Setting",
    icon: "ni ni-settings text-blue ",
    component: Settings,
    layout: "/admin",
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth",
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;