import React, { useState, useEffect, useContext } from "react";
import JobApply from "./JobApply";
// import JobDetails from "./JobDetails";
import Jobs from "./Jobs";
import axios from "axios";
import CreateJob from "./CreateJob";
import JobDetailModal from "./Modal";
import DeleteModal from "./DeleteModal";
import { endpointURL } from "../../../config/config";
import { AuthContext } from "layouts/Admin";

function Index() {
  const [data, setData] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { auth, setAuth } = useContext(AuthContext);

  const selectJobHandler = (data) => {
    setSelectedJob(data);
    setModalOpen(true);
  };

  const deleteJobHandler = (id) => {
    console.log(id);
    setDeleteModal(true);
    setDeleteId(id);
  };

  const getJobs = async () => {
    try {
      const { data } = await axios.get(`${endpointURL.serverUrl}/jobs/`);
      console.log(data);
      setData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDeleteHandler = async () => {
    let token = auth?.token;
    try {
      const { data } = await axios.delete(`${endpointURL.serverUrl}/jobs/${deleteId}`, {
        headers: {
          "x-access-token": token,
        },
      });
      console.log(data);
      setDeleteModal(false);
      setDeleteId("");
      getJobs();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <div>
      <Jobs
        data={data}
        selectJobHandler={selectJobHandler}
        deleteJobHandler={deleteJobHandler}
      />
      {modalOpen && (
        <JobDetailModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          data={selectedJob}
        />
      )}

      <DeleteModal
        modalOpen={deleteModal}
        setModalOpen={setDeleteModal}
        confirmDeleteHandler={confirmDeleteHandler}
      />
    </div>
  );
}

export default Index;