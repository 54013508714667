// src/components/filter.table.js
import React, { useCallback } from "react";
import { endpointURL } from "../config/config";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
      <div className="row mx-0">
          <div className="col-12">
            <span className="float-right my-2 p-2">
                Search:{" "}
                <input
                    className="form-control shadow-lg rounded"
                    value={value || ""}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`Search from ${count} records...`}
                />
            </span>
          </div>
      </div>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`...`}
    />
  );
}

// function DataTable({ columns, data })
const DataTable = ({ columns, data, filters }) => {

  /**
   * Custom Filter Fucntion ----
   * Only filter by: code * name
   */
  const ourGlobalFilterFunction = useCallback((rows, ids, query) => {
    return rows.filter((row) => {
        for (const filter of filters) {
            return row.values[filter].includes(query);
        }
    });
  }, [filters]);

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: 5 },
      // Use our custom function
      globalFilter: ourGlobalFilterFunction
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  return (
    <div>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Table className="align-items-center table-flush" responsive {...getTableProps()}>
        <thead className="thead-light">
            {headerGroups.map((headerGroup) => (
                <>
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                        <th scope="col" {...column.getHeaderProps()}>
                            {column.render("Header")}
                            {/* Render the columns filter UI */}
                            {/* <div className="mt-2">{column.canFilter ? column.render("Filter") : null}</div> */}
                        </th>
                        ))}
                    </tr>
                </>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr scope="row" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                     if (cell.column.Header === 'RESUME') {
                        return (
                          <td {...cell.getCellProps()} className="px-6">
                              <a className="btn btn-sm btn-primary" href={endpointURL.imageUrl+cell.value} target="_blank">view</a>
                          </td>
                        )
                      } else {
                          return (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          );
                      }
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      
      <br />
      <div className="row mx-0 py-2" style={{ backgroundColor: "#f6f9fc", color: "#8898aa"}}>
        <div className="col-4 d-flex align-items-center">
          <p>Showing the first 20 results of {page.length} rows</p>
          {/* <div>
            <pre>
              <code>{JSON.stringify(state.filters, null, 2)}</code>
            </pre>
          </div> */}
        </div>
        <div className="col-8">
          <ul className="pagination float-right my-0">
            <li
              className="page-item my-0"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <a className="page-link">First</a>
            </li>
            <li
              className="page-item my-0"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <a className="page-link">{"<"}</a>
            </li>
            <li
              className="page-item my-0"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <a className="page-link">{">"}</a>
            </li>
            <li
              className="page-item my-0"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <a className="page-link">Last</a>
            </li>
            <li className="d-flex align-items-center bg-white my-0 p-3 mx-2 shadow rounded" style={{ height: "38px" }}>
                <p>Page</p>{" "}
                <strong>
                 { pageIndex + 1} of {pageOptions.length}
                </strong>
            </li>
            <li className="d-flex align-items-center bg-white my-0 px-3 mx-2 shadow rounded">
                <input
                  className="form-control"
                  type="number"
                  min="0"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "100px", height: "20px" }}
                />
            </li>{" "}
            <select
              className="form-control"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              style={{ width: "120px", height: "38px" }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </ul>
        </div>
      </div>
        {/* <div>
            <pre>
                <code>{JSON.stringify(state.filters, null, 2)}</code>
            </pre>
        </div> */}
    </div>
  );
};

export default DataTable;
