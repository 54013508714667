import React from "react";
import "../../../style.css";
import Logo from "../../../assets/img/brand/Richy_Group_Logo_1.png";

const JobApply = ({ setJobSelected }) => {
  return (
    <div>
      <header className="site-header">
        <div
          className="site-header--inner container"
          style={{ marginBottom: "10px" }}
        >
          <div className="logo">
            <img src={Logo} alt="" className="" />
          </div>
          <p>
            {/* <a href="index.html" class="theme-button">
              View Jobs
            </a> */}
          </p>
        </div>
      </header>
      <main id="main" className="content container">
        <section className="job-details">
          <div className="job-description grey-bg bottom-bordered">
            <div className="description-header">
              <div>
                <p className="back-to-jobs">
                  <i className="fas fa-arrow-left"></i>{" "}
                  <a href="index.html">Job Openings</a>
                </p>
                <h1 className="job-title">Chief Accountant</h1>
                <h6 className="job-meta">Finance · manama, manama</h6>
              </div>
              <div className="job-desc-logo">
                <img
                  src="images/Richy_Group_Logo_1.png"
                  alt=""
                  style={{ width: "100px" }}
                />
              </div>
            </div>
            <div className="form-wrapper">
              <form className="application-form">
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="firstName">First Name*</label>
                    <input
                      type="text"
                      name=""
                      id="firstName"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group form-col-50">
                    <label htmlFor="lastName">Last Name*</label>
                    <input
                      type="text"
                      name=""
                      id="lastName"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="email">Date of Birth*</label>
                    <input
                      type="date"
                      name=""
                      id="dateAvailable"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="email">Nationality*</label>
                    <input
                      type="text"
                      name=""
                      id="nationality"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="email">Academic qualification*</label>
                    <input
                      type="text"
                      name=""
                      id="qualification"
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="email">Year of Graduation*</label>
                    <input
                      type="text"
                      name=""
                      id="qualification"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="phone">Mobile Number*</label>
                    <input
                      type="tel"
                      name=""
                      id="phone"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="email">Email*</label>
                    <input
                      type="email"
                      name=""
                      id="email"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="address">Years of experience*</label>
                    <input
                      type="number"
                      name=""
                      id="experience"
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="address">Address*</label>
                    <input
                      type="text"
                      name=""
                      id="address"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-30">
                    <label htmlFor="city">City*</label>
                    <input
                      type="text"
                      name=""
                      id="city"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group form-col-30">
                    <label htmlFor="province">Province*</label>
                    <input
                      type="text"
                      name=""
                      id="province"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-col-30">
                    <label htmlFor="postalCode">Postal Code*</label>
                    <input
                      type="text"
                      name=""
                      id="postalCode"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-30">
                    <label htmlFor="country">Country*</label>
                    <select
                      name=""
                      id="country"
                      className="form-control"
                      required
                    >
                      <option value="">Bahrain</option>
                    </select>
                  </div>
                </div>
                <div className="form-section">
                  <div className="form-row">
                    <div className="form-group form-col-30">
                      <label htmlFor="file-upload">Resume*</label>
                      <br />
                      <label
                        htmlFor="file-upload"
                        className="custom-file-upload"
                      >
                        Choose File
                        <input id="file-upload" type="file" />
                      </label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group form-col-30">
                      <label htmlFor="dateAvailable">Date Available</label>
                      <input
                        type="date"
                        name=""
                        id="dateAvailable"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group form-col-30">
                      <label htmlFor="desiredPay">Desired Pay</label>
                      <input
                        type="text"
                        name=""
                        id="desiredPay"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="lastSalary">
                      What is your salary at your last job?*
                    </label>
                    <input
                      type="text"
                      name=""
                      id="lastSalary"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="availability">
                      When would you be available to start?*
                    </label>
                    <input
                      type="text"
                      name=""
                      id="availability"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="expectedSalary">
                      What is your expected salary?*
                    </label>
                    <input
                      type="text"
                      name=""
                      id="expectedSalary"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-col-50">
                    <label htmlFor="experience">
                      How many years of experience do you have?*
                    </label>
                    <input
                      type="number"
                      name=""
                      id="experience"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-actions">
                  <input
                    type="submit"
                    className="theme-button"
                    value="Submit"
                  />
                  <a href="job-detail.html" className="cancel-link">
                    Cancel
                  </a>
                </div>
              </form>
            </div>
          </div>
          <aside className="job-sidebar">
            <div className="grey-bg">
              <div className="actions">
                <p
                  className="theme-button apply-btn"
                  onClick={() => setJobSelected(false)}
                >
                  View Job Description
                </p>
              </div>
              <div className="job-link">
                <p>Link to this job</p>
                <input
                  type="text"
                  id="jobLink"
                  className="form-control"
                  value="https://example.com"
                  readOnly
                  onClick={() => console.log("Clicked")}
                />
              </div>
              <div className="social-links">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
            </div>
            <div className="job-meta-list">
              <ul>
                <li>
                  <p>
                    <span>Location</span>manama, manama
                  </p>
                </li>
                <li>
                  <p>
                    <span>Department</span>Finance
                  </p>
                </li>
                <li>
                  <p>
                    <span>Employment Type</span>Full-Time
                  </p>
                </li>
                <li>
                  <p>
                    <span>Minimum Experience</span>Experienced
                  </p>
                </li>
              </ul>
            </div>
          </aside>
        </section>
      </main>
      <footer className="site-footer">
        <div className="site-footer--inner">
          <div className="container">
            <span className="copyright-text">
              <a href="#">Privacy Policy</a> · <a href="#">Terms of Service</a>{" "}
              · &copy; 2008-2021 Richy All rights reserved.
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default JobApply;
