import React, { useState, useContext, useEffect } from "react";
import "../../../style.css";
import SingleJob from "components/SingleJob/SingleJob";
import Logo from "../../../assets/img/brand/Richy_Group_Logo_1.png";
import Header from "components/Headers/Header.js";
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
  Alert,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AuthContext } from "layouts/Admin";
import axios from "axios";
import { endpointURL } from "../../../config/config";
import { convertToRaw, EditorState } from "draft-js";

function CreateJob() {
  const [departmentList, setDepartmentList] = useState([]);

  const history = useHistory();

  const [title, setTitle] = useState("");
  const [requirements, setRequirements] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [department, setDepartment] = useState("");
  const [jobType, setJobType] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState("");

  const [titleAr, setTitleAr] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");
  const [requirementsAr, setRequirementsAr] = useState("");
  const [locationAr, setLocationAr] = useState("");
  const [jobTypeAr, setJobTypeAr] = useState("");
  const [experience, setExperience] = useState("");

  const [nextStep, setNextStep] = useState(false);

  const { auth, setAuth } = useContext(AuthContext);

  const renderError = (name) => {
    if (error[name]) {
      return (
        <Alert className="mt-3" color="danger">
          {error[name]}
        </Alert>
      );
    }
  };

  const getDepartments = async () => {
    try {
      const { data } = await axios.get(`${endpointURL.serverUrl}/departments`);
      setDepartmentList(data.data);
    } catch (error) {
      console.log("error");
    }
  };

  const createJobHandler = async () => {
    setError("");
    let token = auth?.token;
    let errors = {};
    console.log(nextStep);
    if (nextStep) {
      console.log("hello");
      if (titleAr && descriptionAr && requirementsAr) {
        try {
          console.log("Here");
          const { data } = await axios.post(
            `${endpointURL.serverUrl}/jobs`,
            {
              title,
              title_ar: titleAr,
              description,
              description_ar: descriptionAr,
              requirements,
              requirements_ar: requirementsAr,
              // description: JSON.stringify(
              //   convertToRaw(description?.getCurrentContent())
              // ),
              // description_ar: JSON.stringify(
              //   convertToRaw(description?.getCurrentContent())
              // ),
              // requirements: JSON.stringify(
              //   convertToRaw(requirements?.getCurrentContent())
              // ),
              // requirements_ar: JSON.stringify(
              //   convertToRaw(requirements?.getCurrentContent())
              // ),
              isActive,
              location,
              location_ar: locationAr,
              jobType,
              jobType_ar: jobTypeAr,
              department,
              experience,
            },
            {
              headers: {
                "x-access-token": token,
              },
            }
          );
          console.log(data);
          history.replace("/admin/jobs");
        } catch (err) {
          console.log(err);
        }
      } else {
        if (!titleAr) {
          errors["titleAr"] = "العنوان مطلوب";
        }
        if (!requirements) {
          errors["requirementsAr"] = "المتطلبات مطلوبة";
        }
        if (!description) {
          errors["descriptionAr"] = "الوصف مطلوب";
        }
        // if (!locationAr) {
        //   errors["locationAr"] = "الموقع مطلوب";
        // }

        // if (!jobTypeAr) {
        //   errors["jobTypeAr"] = "نوع الوظيفة مطلوب";
        // }

        setError(errors);
      }
    } else {
      if (
        title &&
        requirements &&
        description &&
        location &&
        department &&
        jobType &&
        isActive &&
        experience
      ) {
        setNextStep(true);
      } else {
        if (!title) {
          errors["title"] = "Title is required";
        }
        if (!requirements) {
          errors["requirements"] = "Requirements is required";
        }
        if (!description) {
          errors["description"] = "Description is required";
        }
        if (!location) {
          errors["location"] = "Location is required";
        }
        if (!department) {
          errors["department"] = "Department is required";
        }
        if (!jobType) {
          errors["jobType"] = "Job Type is required";
        }
        if (!isActive) {
          errors["isActive"] = "Active is required";
        }
        if (!experience) {
          errors["experience"] = "Experience is required";
        }
        setError(errors);
      }
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <>
      <Header hidden={true} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="col-xl-12">
          <div className="bg-secondary shadow card">
            {/* <div className="bg-white border-0 card-header">
              <div className="align-items-center row">
                <div className="col-8">
                  <h3 className="mb-0">Create Jobs</h3>
                </div>
                <div className="text-right col-4">
                  <a href="#pablo" className="btn btn-primary btn-sm">Settings</a>
                </div>
              </div>
            </div> */}
            <div className="card-body">
              <form className="admin-form">
                {nextStep ? (
                  <>
                    {/* Arabic Version */}
                    <div className="pl-lg-0">
                      <div className="row">
                        {/* العنوان */}
                        <div className="col-lg-12">
                          <div className="form-group text-right">
                            <label
                              className="form-control-label mb-2"
                              for="input-username"
                            >
                              العنوان
                            </label>
                            <input
                              dir="rtl"
                              lang="ar"
                              placeholder="العنوان"
                              type="text"
                              autoComplete="العنوان"
                              value={titleAr}
                              onChange={(e) => setTitleAr(e.target.value)}
                              style={{
                                borderColor: renderError("titleAr")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                              className="form-control-alternative form-control admin-field-border"
                            />
                            {renderError("titleAr")}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* وصف */}
                      <div className="col-lg-12">
                        <div className="form-group text-right">
                          <label
                            className="form-control-label mb-2"
                            for="descriptionAr"
                          >
                            وصف
                          </label>
                          <textarea
                            dir="rtl"
                            lang="ar"
                            className="form-control admin-field-border"
                            onChange={(e) => setDescriptionAr(e.target.value)}
                            rows="10"
                            name="descriptionAr"
                          ></textarea>
                          {/* <Editor
                          editorState={descriptionAr}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          editorStyle={{
                            height: "200px",
                          }}
                          onEditorStateChange={(e) => setDescriptionAr(e)}
                          className="admin-field-border"
                        /> */}
                          {renderError("descriptionAr")}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* متطلبات */}
                      <div className="col-lg-12">
                        <div className="form-group text-right">
                          <label
                            className="form-control-label mb-2"
                            for="requirementsAr"
                          >
                            متطلبات
                          </label>
                          <textarea
                            dir="rtl"
                            lang="ar"
                            className="form-control admin-field-border"
                            onChange={(e) => setRequirementsAr(e.target.value)}
                            rows="10"
                            name="requirementsAr"
                          ></textarea>
                          {/* <Editor
                          editorState={requirementsAr}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) => setRequirementsAr(e)}
                          editorStyle={{
                            height: "200px",
                          }}
                          className="admin-field-border"
                        /> */}
                          {renderError("requirementsAr")}
                        </div>
                        {/* <div className="row">
                        {/* متطلبات }
                        <div className="col-lg-12">
                          <div className="form-group text-right">
                            <label
                              className="form-control-label mb-2"
                              for="requirements"
                            >
                              متطلبات
                            </label>
                            <Editor
                              editorState={requirementsAr}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(e) => setRequirementsAr(e)}
                              editorStyle={{
                                height: "200px",
                              }}
                              className="admin-field-border"
                            />
                            {renderError("requirementsAr")}
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {/* English Version */}
                    <div className="pl-lg-0">
                      <div className="row">
                        <div className="col-lg-9">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="input-username"
                            >
                              Job Title
                            </label>
                            <input
                              placeholder="Title"
                              type="text"
                              autoComplete="title"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              style={{
                                borderColor: renderError("title")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                              className="form-control-alternative form-control admin-field-border"
                            />
                            {renderError("title")}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="isActive"
                            >
                              Active
                            </label>
                            <label
                              className="custom-toggle custom-toggle-default"
                              style={{ marginTop: "10px" }}
                            >
                              <input
                                type="checkbox"
                                checked={isActive}
                                onChange={() => setIsActive(!isActive)}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="OFF"
                                data-label-on="ON"
                              ></span>
                            </label>
                            {renderError("isActive")}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="location"
                            >
                              Location
                            </label>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                              style={{
                                borderColor: renderError("location")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                              className="admin-field-border"
                            >
                              <option aria-readonly>Select</option>
                              <option value="Riyadh">Riyadh</option>
                              <option value="Khobar">Khobar</option>
                              <option value="Jeddah">Jeddah</option>
                            </Input>
                            {renderError("location")}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="department"
                            >
                              Department
                            </label>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              value={department}
                              onChange={(e) => setDepartment(e.target.value)}
                              style={{
                                borderColor: renderError("department")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                              className="admin-field-border"
                            >
                              <option aria-readonly>Select</option>
                              {departmentList.map((depart, i) => {
                                return (
                                  <option key={i} value={depart._id}>
                                    {depart?.title}
                                  </option>
                                );
                              })}
                            </Input>
                            {renderError("department")}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="input-username"
                            >
                              Required Experience
                            </label>
                            <input
                              placeholder="Experience"
                              type="text"
                              autoComplete="Experience"
                              value={experience}
                              onChange={(e) => setExperience(e.target.value)}
                              style={{
                                borderColor: renderError("experience")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                              className="form-control-alternative form-control admin-field-border"
                            />
                            {renderError("experience")}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="jobType"
                            >
                              Job Type
                            </label>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              value={jobType}
                              onChange={(e) => setJobType(e.target.value)}
                              style={{
                                borderColor: renderError("jobType")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                              className="admin-field-border"
                            >
                              <option aria-readonly>Select</option>
                              <option value="Full Time">Full Time</option>
                              <option value="Part Time">Part Time</option>
                            </Input>
                            {renderError("jobType")}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="description"
                            >
                              Description
                            </label>
                            <textarea
                              className="form-control admin-field-border"
                              rows="10"
                              name="description"
                              onChange={(e) => setDescription(e.target.value)}
                              style={{
                                borderColor: renderError("description")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                            ></textarea>
                            {/* <Editor

                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label mb-2" for="department">Department</label>
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          value={department}
                          onChange={(e) => setDepartment(e.target.value)}
                          style={{ borderColor: renderError("department") ? '#ff0000!important' : '#cad1d7' }}
                          className="admin-field-border"
                        >
                          <option aria-readonly>Select</option>
                          {departmentList.map((depart, i) => {
                            return (
                              <option key={i} value={depart._id}>
                                {depart?.title}
                              </option>
                            );
                          })}
                        </Input>
                        {renderError("department")}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label mb-2" for="jobType">Job Type</label>
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          value={jobType}
                          onChange={(e) => setJobType(e.target.value)}
                          style={{ borderColor: renderError("jobType") ? '#ff0000!important' : '#cad1d7' }}
                          className="admin-field-border"
                        >
                          <option aria-readonly>Select</option>
                          <option value="Full Time">Full Time</option>
                          <option value="Part Time">Part Time</option>
                        </Input>
                        {renderError("jobType")}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-control-label mb-2" for="description">Description</label>
                        <textarea className="form-control admin-field-border" onChange={(e) => setDescription(e)} rows="10" name="description"></textarea>
                        {/* <Editor
                          editorState={description}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          editorStyle={{
                            height: "200px",
                          }}
                          onEditorStateChange={(e) => setDescription(e)}
                          className="admin-field-border"
                        /> */}
                            {renderError("description")}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="requirements"
                            >
                              Requirements
                            </label>
                            <textarea
                              className="form-control admin-field-border"
                              rows="10"
                              name="requirements"
                              onChange={(e) => setRequirements(e.target.value)}
                              style={{
                                borderColor: renderError("requirements")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                            ></textarea>
                            {/* <Editor

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-control-label mb-2" for="requirements">Requirements</label>
                        <textarea className="form-control admin-field-border" onChange={(e) => setRequirements(e)} rows="10" name="requirements"></textarea>
                        {/* <Editor

                          editorState={requirements}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) => setRequirements(e)}
                          editorStyle={{
                            height: "200px",
                          }}
                          className="admin-field-border"
                        /> */}
                            {renderError("requirements")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="row">
                  <div className="col-xl-3 col-12">
                    <Button
                      className="btn-cs w-100"
                      color="primary"
                      type="button"
                      onClick={createJobHandler}
                    >
                      {nextStep ? "Create Job" : "Next"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default CreateJob;
