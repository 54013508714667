import React, { useState } from "react";

const Footer = ({
  setGeneralJobSelected,
  setdisplayViewJobDescriptonPanel,
  languageToggle
}) => {
  return (
    <>
      <footer className="page-footer">
        <div className="footer">
          <div className="footer-top">
           
            <div className="container py-lg-4 py-2">
              <div className="row justify-content-center py-2">
                  <div className="actions">
                    {languageToggle === "english" ? (
                        <a className="goto-link" href="https://richy.sa/">View Website</a> 
                      ) : (
                        <a className="goto-link" style={{ marginLeft: "20px" }} href="https://richy.sa/ar">زيارة موقع ريتشي</a>
                      )}
                  </div>
              </div>
            </div>
          </div>
          <div className="footer-middle sm-hide">
            <div className="container">

              {/* <div className="row justify-content-center py-2">
                <div className="actions">
                  {languageToggle === "english" ? (
                      <a className="goto-link" href="https://richy.sa/">View website</a> 
                    ) : (
                      <a className="goto-link" style={{ marginLeft: "20px" }} href="https://richy.sa/ar">زيارة موقع ريتشي</a>
                    )}
                </div>
              </div> */}

              <div className="flex-row">
                <div className="col-lg-25">
                  <div className="block">
                    <div className="block-title">
                      <strong>About Richy</strong>
                    </div>
                    <div className="block-content">
                      <ul className="links">
                        <li>
                          <i className="fas fa-caret-right"></i>
                          <a
                            href="https://richy.sa/en/about-us/"
                            title="About us"
                          >
                            About us
                          </a>
                        </li>
                        <li>
                          <i className="fas fa-caret-right"></i>
                          <a
                            href="https://richy.sa/en/certificates-and-awards/"
                            title="Certificates &amp; Awards"
                          >
                            Certificates &amp; Awards
                          </a>
                        </li>
                        <li>
                          <i className="fas fa-caret-right"></i>
                          <a
                            href="https://richy.sa/en/customer/"
                            title="Customers"
                          >
                            Customers
                          </a>
                        </li>
                        <li>
                          <i className="fas fa-caret-right"></i>
                          <a
                            href="https://richy.sa/en/salesman/"
                            title="Salesman"
                          >
                            Salesman
                          </a>
                        </li>
                        <li>
                          <i className="fas fa-caret-right"></i>
                          <a href="https://richy.sa/ar/richy-branches-ar/" title="Branches">
                            Richy Branches
                          </a>
                          <ul className="links branch">
                            <li className="details">
                              <i className="fas fa-caret-right"></i>
                              <a
                                href="https://maps.app.goo.gl/j3hM4eMhazZX6C1AA"
                                title="Riyadh Branch (Tahila)"
                              >
                                Riyadh Branch (Tahila)
                              </a>
                            </li>
                            <li className="details">
                              <i className="fas fa-caret-right"></i>
                              <a
                                href="https://goo.gl/maps/wUSh62UricTagzXt9/"
                                title="Riyadh Branch (Al Khair Road)"
                              >
                                Riyadh Branch (Al Khair Road)
                              </a>
                            </li>
                            <li className="details">
                              <i className="fas fa-caret-right"></i>
                              <a
                                href="https://maps.app.goo.gl/e9pfJr9jKPvT3Et78"
                                title="Khobar Branch"
                              >
                                Khobar Branch
                              </a>
                            </li>
                            <li className="details">
                              <i className="fas fa-caret-right"></i>
                              <a
                                href="https://maps.app.goo.gl/BPPndTk2k6thtDwy7"
                                title="Jeddah Branch"
                              >
                                Jeddah Branch
                              </a>
                            </li>
                            <li className="details">
                              <i className="fas fa-caret-right"></i>
                              <a
                                href="https://goo.gl/maps/j2ByYivJZVtZu5kJ6"
                                title="Arar Branch"
                              >
                                Arar Branch
                              </a>
                            </li>
                            <li className="details">
                              <i className="fas fa-caret-right"></i>
                              <a
                                href="https://goo.gl/maps/uWJkspMoHakFm1nG6"
                                title="Khamis Mushait Branch"
                              >
                                Khamis Mushait Branch{" "}
                              </a>
                            </li>
                            <li className="details">
                              <i className="fas fa-caret-right"></i>
                              <a
                                href="https://goo.gl/maps/ftiLgiLdzCRYbCLZ9"
                                title="Hail Branch"
                              >
                                Hail Branch{" "}
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-25">
                  <div className="block">
                    <div className="block-title">
                      <strong>Support</strong>
                    </div>
                    <div className="block-content">
                      <ul className="features">
                        <li>
                          <i className="fas fa-check"></i>
                          <a href="https://richy.sa/en/faqs/" title="FAQ">
                            FAQ
                          </a>
                        </li>
                        <li>
                          <i className="fas fa-check"></i>
                          <a
                            href="https://richy.sa/en/refundexchange/"
                            title="Refund "
                          >
                            Refund{" "}
                          </a>
                        </li>
                        <li>
                          <i className="fas fa-check"></i>
                          <a
                            href="https://richy.sa/en/privacy-policy/"
                            title="Privacy And Policy"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li>
                          <i className="fas fa-check"></i>
                          <a
                            href="https://richy.sa/en/contact/"
                            title="Contact"
                          >
                            Contact us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-25">
                  <div className="block">
                    <div className="block-title">
                      <strong>Contact Information</strong>
                    </div>
                    <div className="block-content">
                      <ul className="contact-info">
                        <li>
                          <i className="fas fa-map-marker-alt"></i>
                          <p>
                            <strong>Address:</strong>
                            <br />
                            Al Olaya King Fahad Road
                            <br />
                            <strong>City:</strong>Riyadh
                          </p>
                        </li>
                        <li>
                          <i className="fas fa-phone-alt"></i>
                          <p>
                            <strong>Phone:</strong>
                            <br />
                            920014121
                          </p>
                        </li>
                        <li>
                          <i className="far fa-envelope"></i>
                          <p>
                            <strong>Email:</strong>
                            <br />
                            <a href="mailto:recruitment@richy.sa">recruitment@richy.sa</a>
                          </p>
                        </li>
                        <li>
                          <i className="far fa-clock"></i>
                          <p>
                            <strong>Working Days/Hours:</strong>
                            <br />
                            Sun-Thu/ 8:30AM - 4:00PM
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-25">
                  <div className="block newsletter">
                    <div className="block-title">
                      <strong>Be the First to Know</strong>
                    </div>
                    <div className="content">
                      <p></p>
                      <div>
                        Get all the latest information on Events,Sales and
                        Offers.
                        <br />
                        Sign up for newsletter today.
                      </div>
                      <p></p>
                      <label for="footer_newsletter">
                        Enter your email address
                      </label>
                      <form
                        className="form subscribe"
                        novalidate="novalidate"
                        action="https://richy.sa/en/newsletter/subscriber/new/"
                        method="post"
                        id="newsletter-validate-detail"
                        data-hs-cf-bound="true"
                      >
                        <div className="field newsletter">
                          <label className="label" for="footer_newsletter">
                            <span>Sign Up for Our Newsletter:</span>
                          </label>
                          <div className="control">
                            <input
                              name="email"
                              type="email"
                              id="footer_newsletter"
                              data-validate="{required:true, 'validate-email':true}"
                            />
                          </div>
                        </div>
                        <div className="actions">
                          <button
                            className="action subscribe primary"
                            title="Subscribe"
                            type="submit"
                          >
                            <span>Subscribe</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="footer-bottom">
            <div className="container">
              <div className="flex-row">
                <span className="copyright-text">
                  ©Copyright 2022 by Richy Group. All Rights Reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
