import React from "react";
import "../../../style.css";
import Logo from "../../../assets/img/brand/Richy_Group_Logo_1.png";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState } from "draft-js";
import Footer from "components/Footers/Footer";
import Header from "components/Footers/Header";
import { endpointURL } from "../../../config/config";

const JobDetails = ({
  data,
  setJobSelected,
  setSelectedJob,
  languageToggle,
  setLanguageToggle,
  setGeneralJobSelected,
  setdisplayViewJobDescriptonPanel,
}) => {
  console.log("🚀 ~ file: JobDetails.js ~ line 17 ~ data", data);

  const serverUrl = endpointURL.imageUrl;
  return (
    <div id="wrapper">
      <Header setLanguageToggle={setLanguageToggle} />

      <main id="main" className="content container">
        <section className="job-details">
          <div className="job-description bottom-bordered cs-white-bg">
            <div className="description-header">
              <div>
                <p
                  className="back-to-jobs"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                  {"  "}
                  <a onClick={() => setSelectedJob(null)}>Job Openings</a>
                </p>
                {languageToggle === "english" ? (
                  <h1 className="job-title">{data?.title}</h1>
                ) : (
                  <h1 className="job-title">{data?.title_ar}</h1>
                )}
                <h6 className="job-meta">
                  {languageToggle === "english"
                    ? data?.department?.title + " - " + data?.location
                    : data?.department?.title_ar + " - " + data?.location}
                </h6>
              </div>
              <div className="job-desc-logo">
                <img
                  src={serverUrl + data.department?.imagePath}
                  alt=""
                  style={{ width: "140px", height: "130px" }}
                />
              </div>
            </div>
            <div className="description-body">
              <h5>Description</h5>
              <p className="description-text">
                {languageToggle === "english"
                  ? data?.description
                  : data?.description_ar}
              </p>
              {/* <Editor
                toolbarHidden
                readOnly
                editorClassName="editorStyles"
                editorState={EditorState.createWithContent(
                  convertFromRaw(
                    JSON.parse(
                      languageToggle === "english"
                        ? data?.description
                        : data?.description_ar
                    )
                  )
                )}
              /> */}

              <h5>Job Key Requirements</h5>
              <p className="description-text">
                {languageToggle === "english"
                  ? data?.requirements
                  : data?.requirements_ar}
              </p>
              {/* <Editor
                toolbarHidden
                readOnly
                editorClassName="editorStyles"
                editorState={EditorState.createWithContent(
                  convertFromRaw(
                    JSON.parse(
                      languageToggle === "english"
                        ? data?.requirements
                        : data?.requirements_ar
                    )
                  )
                )}
              /> */}
            </div>
            <div style={{ textAlign: "center" }}>
              <p
                className="theme-button apply-btn button-block hideOnDesktop"
                onClick={() => setJobSelected(true)}
              >
                Apply For This Job
              </p>
            </div>
          </div>
          <aside className="job-sidebar showOnDesktop ml-3">
            <div className="grey-bg cs-white-bg">
              <div className="actions">
                <p
                  className="theme-button apply-btn"
                  onClick={() => setJobSelected(true)}
                >
                  Apply For This Job
                </p>
              </div>
              <div className="job-link">
                <p>Link to this job</p>
                <input
                  type="text"
                  id="jobLink"
                  className="form-control"
                  value={`https://careers.richy.group/index/job?id=${data?._id}`}
                  readOnly
                  onClick={(e) => console.log(e)}
                />
              </div>
              <div className="social-links">
                <a href="https://www.linkedin.com/company/saudi-richy">
                  <i class="fab fa-linkedin-in"></i>
                </a>
                <a href="https://twitter.com/SaudiRichy" className="ml-2">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
            </div>
            <div className="job-meta-list">
              <ul>
                <li>
                  <p>
                    <span>Location</span>
                    {data?.location}
                  </p>
                </li>
                <li>
                  <p>
                    <span>Department</span>
                    {data?.department?.title}
                  </p>
                </li>
                <li>
                  <p>
                    <span>Employment Type</span>
                    {data?.jobType}
                  </p>
                </li>
                <li>
                  <p>
                    <span>Minimum Experience</span>{data?.experience} Years
                  </p>
                </li>
              </ul>
            </div>
          </aside>
        </section>
      </main>

      <Footer
        setGeneralJobSelected={setGeneralJobSelected}
        setdisplayViewJobDescriptonPanel={setdisplayViewJobDescriptonPanel}
        languageToggle={languageToggle}
      />

    </div>
  );
};

export default JobDetails;
