import React, { useState, useEffect, useContext, Children } from "react";
import "../../../style.css";
import SingleJob from "components/SingleJob/SingleJob";
import Logo from "../../../assets/img/brand/Richy_Group_Logo_1.png";
import Header from "components/Headers/Header.js";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
  Alert,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AuthContext } from "layouts/Admin";
import { endpointURL } from "../../../config/config";
import axios from "axios";

function EditJob() {
  const [departmentList, setDepartmentList] = useState([]);

  const history = useHistory();

  const location = useLocation();
  const [title, setTitle] = useState("");
  const [requirements, setRequirements] = useState(""); //EditorState.createEmpty()
  const [description, setDescription] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [department, setDepartment] = useState("");
  const [jobType, setJobType] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState("");

  const [titleAr, setTitleAr] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");
  const [requirementsAr, setRequirementsAr] = useState("");
  const [locationAr, setLocationAr] = useState("");
  const [jobTypeAr, setJobTypeAr] = useState("");
  const [experience, setExperience] = useState("");

  const [nextStep, setNextStep] = useState(false);

  const { auth, setAuth } = useContext(AuthContext);

  console.log("Location Ar", locationAr);
  console.log("Desc: ", location.state.data?.description);

  useEffect(() => {
    getDepartments();
    console.log("data edit", location.state.data._id);
    const data = location.state.data;
    setTitle(data?.title);
    setTitleAr(data?.title_ar);
    setIsActive(data?.isActive);
    console.log(data);
    setJobLocation(data?.location);
    setLocationAr(data?.location_ar);
    setJobType(data?.jobType);
    setJobTypeAr(data?.jobType_ar);
    setDepartment(data?.department?._id);
    setDescription(data?.description);
    setDescriptionAr(data?.description_ar);
    setRequirements(data?.requirements);
    setRequirementsAr(data?.requirements_ar);
    setExperience(data?.experience)
    // setDescription(
    //   EditorState.createWithContent(
    //     convertFromRaw(JSON.parse(data?.description))
    //   )
    // );
    // setDescriptionAr(
    //   EditorState.createWithContent(
    //     convertFromRaw(JSON.parse(data?.description_ar))
    //   )
    // );
    // setRequirements(
    //   EditorState.createWithContent(
    //     convertFromRaw(JSON.parse(data?.requirements))
    //   )
    // );
    // setRequirementsAr(
    //   EditorState.createWithContent(
    //     convertFromRaw(JSON.parse(data?.requirements_ar))
    //   )
    // );
  }, []);

  const getDepartments = async () => {
    try {
      const { data } = await axios.get(`${endpointURL.serverUrl}/departments`);
      setDepartmentList(data.data);
    } catch (error) {
      console.log("error");
    }
  };

  const renderError = (name) => {
    if (error[name]) {
      return (
        <Alert className="mt-0" color="danger">
          {error[name]}
        </Alert>
      );
    }
  };

  const editJobHandler = async () => {
    setError("");
    let token = auth?.token;
    let errors = {};
    if (nextStep) {
     console.log('submitting ....')
      console.log('isActive : ',isActive);



      if (titleAr && descriptionAr && requirementsAr) {
        try {
          console.log("Here");
          const { data } = await axios.post(
            `${endpointURL.serverUrl}/jobs/update/${location.state.data._id}`,
            {
              title,
              title_ar: titleAr,
              description: description, //JSON.stringify(convertToRaw(description?.getCurrentContent())),
              description_ar: descriptionAr,
              requirements: requirements,
              requirements_ar: requirementsAr,
              isActive,
              location: jobLocation,
              jobType,
              // jobType_ar: jobTypeAr,
              department,
              experience,
            },
            {
              headers: {
                "x-access-token": token,
              },
            }
          );
          console.log(data);
          history.replace("/admin/jobs");
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("Arabic validations");
        if (!titleAr) {
          errors["titleAr"] = "العنوان مطلوب";
        }
        if (!requirementsAr) {
          errors["requirementsAr"] = "المتطلبات مطلوبة";
        }
        if (!descriptionAr) {
          errors["descriptionAr"] = "الوصف مطلوب";
        }
        if (!locationAr) {
          errors["locationAr"] = "الموقع مطلوب";
        }

        if (!jobTypeAr) {
          errors["jobTypeAr"] = "نوع الوظيفة مطلوب";
        }

        console.log(errors);

        setError(errors);
      }
    } else {
      console.log("English validation ");
      console.log("isActive : ", isActive);
      if (
        title &&
        requirements &&
        description &&
        location &&
        department &&
        jobType &&
        experience
        // isActive
      ) {
        setNextStep(true);
      } else {
        if (!title) {
          errors["title"] = "Title is required";
        }
        if (!requirements) {
          errors["requirements"] = "Requirements is required";
        }
        if (!description) {
          errors["description"] = "Description is required";
        }
        if (!location) {
          errors["location"] = "Location is required";
        }
        if (!department) {
          errors["department"] = "Department is required";
        }
        if (!jobType) {
          errors["jobType"] = "Job Type is required";
        }
        if (!experience) {
          errors["experience"] = "Experience is required";
        }
        // if (!isActive) {
        //   errors["isActive"] = "Active is required";
        // }
        setError(errors);
      }
    }
  };

  return (
    <>
      <Header hidden={true} />
      {/* Page content */}
      <Container className="mt--8" fluid>
        <div className="col-xl-12">
          <div className="card bg-secondary shadow">
            <div className="card-body">
              <form className="admin-form">
                {nextStep ? (
                  <>
                    {/* Arabic Version */}
                    <div className="pl-lg-0">
                      <div className="row">
                        {/* <div className="col-xl-4 col-12">
                                        <FormGroup>
                                            <Label
                                            for="jobTypeAr"
                                            className="mb-2 text-right"
                                            >
                                            نوع الوظيفة
                                            </Label>
                                            <InputGroup className="input-group-alternative px-3">
                                            <InputGroupAddon addonType="prepend"></InputGroupAddon>
                                            <Input
                                                dir="rtl"
                                                lang="ar"
                                                type="select"
                                                name="select"
                                                id="jobTypeAr"
                                                value={jobTypeAr}
                                                onChange={(e) => setJobTypeAr(e.target.value)}
                                            >
                                                <option aria-readonly>تحديد</option>
                                                <option value="وقت كامل">وقت كامل</option>
                                                <option value="دوام جزئى">دوام جزئى</option>
                                            </Input>
                                            </InputGroup>
                                            {renderError("jobTypeAr")}
                                        </FormGroup>
                                        </div>
                                        <div className="col-xl-4 col-12">
                                        <FormGroup>
                                            <Label
                                            for="locationAr"
                                            className="mb-2 text-right"
                                            >
                                            موقع
                                            </Label>
                                            <InputGroup className="input-group-alternative px-3">
                                            <InputGroupAddon addonType="prepend"></InputGroupAddon>
                                            <Input
                                                dir="rtl"
                                                lang="ar"
                                                type="select"
                                                name="select"
                                                id="locationAr"
                                                value={locationAr}
                                                onChange={(e) => setLocationAr(e.target.value)}
                                            >
                                                <option aria-readonly>تحديد</option>
                                                <option value="الرياض">الرياض</option>
                                                <option value="ٱلْخُبَر">ٱلْخُبَر</option>
                                                <option value="جدة">جدة</option>
                                            </Input>
                                            </InputGroup>
                                            {renderError("locationAr")}
                                        </FormGroup>
                                        </div> */}
                        <div className="col-xl-4 col-12">
                          <FormGroup>
                            <Label
                              for="exampleSelect"
                              className="mb-2 text-right"
                            >
                              العنوان
                            </Label>
                            <InputGroup className="input-group-alternative  px-3">
                              <InputGroupAddon addonType="prepend"></InputGroupAddon>
                              <Input
                                dir="rtl"
                                lang="ar"
                                placeholder="العنوان"
                                type="text"
                                autoComplete="العنوان"
                                value={titleAr}
                                onChange={(e) => setTitleAr(e.target.value)}
                              />
                            </InputGroup>
                            {renderError("titleAr")}
                          </FormGroup>
                        </div>
                      </div>

                      {/* وصف */}
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group text-right">
                            <label
                              className="form-control-label mb-2"
                              for="descriptionAr"
                            >
                              وصف
                            </label>
                            <textarea
                              className="form-control admin-field-border"
                              onChange={(e) => setDescriptionAr(e.target.value)}
                              rows="10"
                              name="descriptionAr"
                              value={descriptionAr}
                            ></textarea>

                            {renderError("descriptionAr")}
                          </div>
                        </div>
                      </div>

                      {/* متطلبات */}
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group text-right">
                            <label
                              className="form-control-label mb-2"
                              for="requirementsAr"
                            >
                              متطلبات
                            </label>
                            <textarea
                              className="form-control admin-field-border"
                              onChange={(e) =>
                                setRequirementsAr(e.target.value)
                              }
                              rows="10"
                              name="requirementsAr"
                              value={requirementsAr}
                            ></textarea>

                            {renderError("requirementsAr")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {/* English Version */}
                    <div className="pl-lg-0">
                      <div className="row">
                        <div className="col-lg-9 col-9">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="input-username"
                            >
                              Job Title
                            </label>
                            <input
                              placeholder="Title"
                              type="text"
                              autoComplete="title"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              style={{
                                borderColor: renderError("title")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                              className="form-control-alternative form-control admin-field-border"
                            />
                            {renderError("title")}
                          </div>
                        </div>
                        <div className="col-lg-3 col-3">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="isActive"
                            >
                              Active
                            </label>
                            <label
                              className="custom-toggle custom-toggle-default"
                              style={{ marginTop: "10px" }}
                            >
                              <input
                                type="checkbox"
                                checked={isActive}
                                onChange={() => setIsActive(!isActive)}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="OFF"
                                data-label-on="ON"
                              ></span>
                            </label>
                            {renderError("isActive")}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="location"
                            >
                              Location
                            </label>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              value={jobLocation}
                              onChange={(e) => setJobLocation(e.target.value)}
                              style={{
                                borderColor: renderError("jobLocation")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                              className="admin-field-border"
                            >
                              <option aria-readonly>Select</option>
                              <option value="Riyadh">Riyadh</option>
                              <option value="Khobar">Khobar</option>
                              <option value="Jeddah">Jeddah</option>
                            </Input>
                            {renderError("jobLocation")}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="department"
                            >
                              Department
                            </label>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              value={department}
                              onChange={(e) => setDepartment(e.target.value)}
                              style={{
                                borderColor: renderError("department")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                              className="admin-field-border"
                            >
                              <option aria-readonly>Select</option>
                              {departmentList.map((depart, i) => {
                                return (
                                  <option key={i} value={depart._id}>
                                    {depart?.title}
                                  </option>
                                );
                              })}
                            </Input>
                            {renderError("department")}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="input-username"
                            >
                              Required Experience
                            </label>
                            <input
                              placeholder="Experience"
                              type="text"
                              autoComplete="Experience"
                              value={experience}
                              onChange={(e) => setExperience(e.target.value)}
                              style={{
                                borderColor: renderError("experience")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                              className="form-control-alternative form-control admin-field-border"
                            />
                            {renderError("experience")}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="jobType"
                            >
                              Job Type
                            </label>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              value={jobType}
                              onChange={(e) => setJobType(e.target.value)}
                              style={{
                                borderColor: renderError("jobType")
                                  ? "#ff0000!important"
                                  : "#cad1d7",
                              }}
                              className="admin-field-border"
                            >
                              <option aria-readonly>Select</option>
                              <option value="Full Time">Full Time</option>
                              <option value="Part Time">Part Time</option>
                            </Input>
                            {renderError("jobType")}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="description"
                            >
                              Description
                            </label>
                            <textarea
                              className="form-control admin-field-border"
                              onChange={(e) => setDescription(e.target.value)}
                              rows="10"
                              name="description"
                              value={description}
                            ></textarea>
                            {renderError("description")}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label
                              className="form-control-label mb-2"
                              for="requirements"
                            >
                              Requirements
                            </label>
                            <textarea
                              className="form-control admin-field-border"
                              onChange={(e) => setRequirements(e.target.value)}
                              rows="10"
                              name="requirements"
                              value={requirements}
                            ></textarea>
                            {renderError("requirements")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="row">
                  <div className="col-xl-3 col-12">
                    <Button
                      className="my-4 w-100"
                      color="primary"
                      type="button"
                      onClick={editJobHandler}
                    >
                      {nextStep ? "Update Job" : "Next"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default EditJob;
